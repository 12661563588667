import React from 'react';

const Overlay = () => {
  return (
    <div style={styles.overlay}></div>
  );
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    zIndex: 999,
  },
};

export default Overlay;
