import * as d3 from 'd3';

export const renderEnergyEndUseGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  // Check if chartRef or selectedFeatures is valid
  if (!chartRef?.current) {
    console.error("chartRef is null or undefined:", chartRef);
    return;
  }

  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  // Filter out invalid features
  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature?.geometry?.type !== 'Point'
  );

  const monthlyData = Array.from({ length: 12 }, () => ({
    equipment: 0,
    lighting: 0,
    hotWater: 0,
    cooling: 0,
    heating: 0,
  }));

  // Iterate over filtered features and ensure properties exist
  filteredFeatures.forEach((feature) => {
    const equipment = Array.isArray(feature?.properties?.["Equipment[KWh][Mth]"])
      ? feature.properties["Equipment[KWh][Mth]"]
      : [];
    const lighting = Array.isArray(feature?.properties?.["Lighting[KWh][Mth]"])
      ? feature.properties["Lighting[KWh][Mth]"]
      : [];
    const hotWater = Array.isArray(feature?.properties?.["HotWater[KWh][Mth]"])
      ? feature.properties["HotWater[KWh][Mth]"]
      : [];
    const cooling = Array.isArray(feature?.properties?.["Cooling[KWh][Mth]"])
      ? feature.properties["Cooling[KWh][Mth]"]
      : [];
    const heating = Array.isArray(feature?.properties?.["Heating[KWh][Mth]"])
      ? feature.properties["Heating[KWh][Mth]"]
      : [];

    // Iterate over months and accumulate data
    for (let month = 0; month < 12; month++) {
      if (Array.isArray(equipment)) monthlyData[month].equipment += equipment[month] || 0;
      if (Array.isArray(lighting)) monthlyData[month].lighting += lighting[month] || 0;
      if (Array.isArray(hotWater)) monthlyData[month].hotWater += hotWater[month] || 0;
      if (Array.isArray(cooling)) monthlyData[month].cooling += cooling[month] || 0;
      if (Array.isArray(heating)) monthlyData[month].heating += heating[month] || 0;
    }
  });

  // Filter out keys with no valid data
  const keys = Object.keys(monthlyData[0]).filter(key => {
    return monthlyData.some(month => month[key] > 0);
  });

  // Defensive check for valid keys
  if (!keys.length) {
    console.error("No valid keys found for monthlyData:", monthlyData);
    return;
  }

  const margin = { top: 0, right: 30, bottom: 70, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 250) - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleBand()
    .domain(d3.range(12)) // 12 months, index from 0 to 11
    .range([0, width])
    .padding(0.1);

  const y = d3.scaleLinear()
    .domain([0, d3.max(monthlyData, d => keys.reduce((sum, key) => sum + d[key], 0))])
    .nice()
    .range([height, 0]);

  const colorScale = d3.scaleOrdinal()
    .domain(['equipment', 'lighting', 'hotWater', 'cooling', 'heating'])
    .range(['#959595', '#f2e75c', '#eec176', '#6faadd', '#cf5867']);

  // Create tooltip
  const tooltip = d3.select('body').append('div')
    .style('position', 'absolute')
    .style('background', 'white')
    .style('padding', '5px 10px')
    .style('border', '1px solid #ccc')
    .style('border-radius', '5px')
    .style('pointer-events', 'none')
    .style('opacity', 0);

  const formatNumberWithCommas = d3.format(','); // Formatter for comma-separated numbers

  let tooltipVisible = false; // Track tooltip visibility

  svg.selectAll('g.layer')
    .data(d3.stack().keys(keys)(monthlyData))
    .enter().append('g')
    .attr('class', 'layer')
    .attr('fill', d => colorScale(d.key))
    .selectAll('rect')
    .data(d => d)
    .enter().append('rect')
    .attr('x', (d, i) => x(i))
    .attr('y', d => y(d[1]))
    .attr('height', d => y(d[0]) - y(d[1]))
    .attr('width', x.bandwidth())
    .on('click', (event, d) => {
      const totalForMonth = keys.reduce((sum, key) => sum + d.data[key], 0);
      const keyValuePairs = keys.map(key => {
        const percentage = ((d.data[key] / totalForMonth) * 100).toFixed(2);
        return `${key}: ${formatNumberWithCommas(Math.round(d.data[key]))} (${percentage}%)`;
      }).join('<br>');

      if (tooltipVisible) {
        tooltip.transition().duration(500).style('opacity', 0);
        tooltipVisible = false;
      } else {
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${keyValuePairs}`)
          .style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 20) + 'px');
        tooltipVisible = true;
      }
    });

  // Add click event listener to document to hide tooltip if clicked outside
  document.addEventListener('click', (event) => {
    if (!chartRef?.current || !tooltipVisible) return; // Defensive check for chartRef and tooltip visibility
    const isClickInside = chartRef.current.contains(event.target); // Ensure chartRef.current exists
    if (!isClickInside) {
      tooltip.transition().duration(500).style('opacity', 0);
      tooltipVisible = false;
    }
  });

  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickFormat((i) => i + 1))
    .selectAll("text");

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')))
    .selectAll("text");

  svg.append("text")
    .attr("class", "x-axis-label")
    .attr("x", width + 10)
    .attr("y", height + 20)
    .attr("text-anchor", "middle")
    .style("font-size", "10px")
    .text("Mth");

  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  const categories = ['equipment', 'lighting', 'hotWater', 'cooling', 'heating'];
  const legendXOffset = 20;
  const legendYOffset = 10;

  categories.forEach((category, i) => {
    const row = Math.floor(i / 3);
    const col = i % 3;

    legend.append('rect')
      .attr('x', col * 100)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));

    legend.append('text')
      .attr('x', col * 100 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(category)
      .style('font-size', '12px')
      .style('fill', '#000');
  });
};
