// Import necessary libraries and utility functions
import proj4 from "proj4";
import {
  parseStringArrays,
  coordinatesOutOfRange,
  reprojectGeoJSON,
  addIdToGeoJson,
  collectDataDashboardProperties,
  determineType,
  generateDefaultColor,
  readFileAsText,
  reasonableRoundUp,
  reasonableRoundDown,
  calculatePropertySums,
} from "./Utilities";

// Helper function to generate unique scenario names
const generateUniqueScenarioName = (name, scenarioNamesSet) => {
  let uniqueName = name;
  let counter = 1;
  while (scenarioNamesSet.has(uniqueName)) {
    uniqueName = `${name} (${counter})`;
    counter++;
  }
  scenarioNamesSet.add(uniqueName);
  return uniqueName;
};

// Helper function to determine the prefix based on the dashboard name
const determinePrefix = (dashboardName) => {
  if (dashboardName.includes("EnergyGrid")) return "G";
  if (dashboardName.includes("StreetMobility")) return "S";
  if (
    dashboardName.includes("EnergyBuilding") ||
    dashboardName.includes("BuildingCO2")
  )
    return "B";
  return "D"; // Default to 'D' for DataDashboard or other unspecified types
};

// Helper function to update numeric properties map and collect property types
const updatePropertiesAndTypes = (
  numericProperties,
  propertyTypes,
  feature,
  dashboardNames,
  config
) => {
  Object.keys(feature.properties || {}).forEach((prop) => {
    let value = feature.properties[prop];
    // Convert string numeric values to numbers
    if (typeof value === "string" && !isNaN(value)) {
      value = Number(value);
    }

    // Determine the type of the property using determineType
    const propType = determineType(value);

    // Store the type in propertyTypes map
    if (!propertyTypes.has(prop)) {
      propertyTypes.set(prop, propType);
    }

    // Check if the value is numeric and update min/max accordingly
    if (value !== null && typeof value === "number") {
      if (!numericProperties.has(prop)) {
        numericProperties.set(prop, { min: value, max: value });
      } else {
        const current = numericProperties.get(prop);
        numericProperties.set(prop, {
          min: Math.min(current.min, value),
          max: Math.max(current.max, value),
        });
      }

      // Ensure config[dashboardName] and chartProperties exist
      dashboardNames.forEach((dashboardName) => {
        if (!config[dashboardName]) {
          config[dashboardName] = {}; // Initialize dashboard config if it doesn't exist
        }
        if (!config[dashboardName].chartProperties) {
          config[dashboardName].chartProperties = []; // Initialize chartProperties if it doesn't exist
        }
        // Add numeric property to the appropriate dashboard's chartProperties, avoiding duplicates
        if (!config[dashboardName].chartProperties.includes(prop)) {
          config[dashboardName].chartProperties.push(prop);
        }
      });
    }
  });
};

export const FileUploadLogic = async (
  files,
  config,
  setConfig,
  setFileProgress
) => {
  // Initialize variables for dashboards, properties, and scenario names
  const dashboards = {};
  const scenarioNamesSet = new Set();
  const tiledDataFeatures = {};

  // Create a map to store property types and numeric properties for each dashboard
  const dashboardPropertyTypes = {};
  const dashboardNumericProperties = {};

  // Create a set of named dashboards from the existing config
  const namedDashboards = new Set(Object.keys(config));

  // Iterate through each file to process GeoJSON data
  for (const [index, file] of files.entries()) {
    // Read the file as text and parse as JSON
    let json = await readFileAsText(file);

    // Create a mapping from lower-case dashboard names to actual config keys
    const configKeysMap = {};
    Object.keys(config).forEach((key) => {
      configKeysMap[key.toLowerCase()] = key;
    });

    // Check if any coordinates are out of range in the GeoJSON
    let coordinatesAreOutOfRange = false;

    for (const feature of json.features) {
      if (coordinatesOutOfRange(feature.geometry.coordinates)) {
        coordinatesAreOutOfRange = true;
        break; // No need to check further if one is out of range
      }
    }

    // Extract source projection from the GeoJSON's CRS property
    let sourceProj = "EPSG:4326"; // Default to WGS84
    if (json.crs && json.crs.properties && json.crs.properties.name) {
      const crsName = json.crs.properties.name;
      // Extract EPSG code from crsName, which is in format "urn:ogc:def:crs:EPSG::32118"
      const match = crsName.match(/EPSG::(\d+)/);
      if (match && match[1]) {
        sourceProj = `EPSG:${match[1]}`;
      }
    }

    // Ensure the source projection is defined in proj4
    if (!proj4.defs(sourceProj)) {
      console.error(`Projection ${sourceProj} is not defined in proj4.`);
    }

    // If source projection is not EPSG:4326, reproject the entire GeoJSON to EPSG:4326
    if (sourceProj !== "EPSG:4326") {
      json = reprojectGeoJSON(json, sourceProj, "EPSG:4326");
    }

    let dashboardNames = [];
    let scenarioName = file.name;

    // Determine scenario name from the first feature's properties if available
    if (json.features && json.features.length > 0) {
      const firstFeature = json.features[0];

      if (firstFeature.properties && firstFeature.properties.name) {
        scenarioName = firstFeature.properties.name;
      }

      scenarioName = generateUniqueScenarioName(scenarioName, scenarioNamesSet);

      // Collect dashboard names from feature properties if they exist
      json.features.forEach((feature) => {
        if (feature.properties && feature.properties.type) {
          const names = feature.properties.type
            .split(",")
            .map((name) => name.trim().toLowerCase());
          dashboardNames.push(...names);
        }
      });
    }

    // If no dashboard names are found, default to "DataDashboard"
    if (dashboardNames.length === 0) {
      dashboardNames = ["DataDashboard"];
    }

    // Inside your main loop where you process dashboard names
    dashboardNames.forEach((dashboardName) => {
      const lowerCaseDashboardName = dashboardName.toLowerCase();
      const actualConfigKey = configKeysMap[lowerCaseDashboardName];

      // Proceed only if the dashboard exists in the config
      if (actualConfigKey && config[actualConfigKey]) {
        // Initialize property maps if not already done
        if (!dashboardPropertyTypes[actualConfigKey]) {
          dashboardPropertyTypes[actualConfigKey] = new Map();
        }
        if (!dashboardNumericProperties[actualConfigKey]) {
          dashboardNumericProperties[actualConfigKey] = new Map();
        }

        // Iterate through each feature to update properties and types
        json.features.forEach((feature) => {
          // Update properties and types for this dashboard
          updatePropertiesAndTypes(
            dashboardNumericProperties[actualConfigKey],
            dashboardPropertyTypes[actualConfigKey],
            feature,
            [actualConfigKey],
            config
          );
        });

        // Find matching dashboard config
        const prefix = determinePrefix(actualConfigKey);
        const jsonWithIDs = addIdToGeoJson(json, prefix);

        if (config[actualConfigKey]?.tiledData) {
          if (!tiledDataFeatures[actualConfigKey]) {
            tiledDataFeatures[actualConfigKey] = [];
          }
          tiledDataFeatures[actualConfigKey].push(...jsonWithIDs.features);
        } else {
          if (!dashboards[actualConfigKey]) {
            dashboards[actualConfigKey] = [];
          }
          dashboards[actualConfigKey].push({
            name: scenarioName,
            data: jsonWithIDs,
          });
        }
      }
    });

    // Update file progress
    setFileProgress((prevProgress) => ({
      ...prevProgress,
      [file.name]: 100, // Assuming file processing is complete
    }));
  }

  // Process tiled data after all files have been read
  Object.keys(tiledDataFeatures).forEach((dashboardName) => {
    const combinedFeatures = tiledDataFeatures[dashboardName];
    const combinedJson = {
      type: "FeatureCollection",
      features: combinedFeatures,
    };
    const scenarioName = `${dashboardName}_Combined`;
    if (!dashboards[dashboardName]) {
      dashboards[dashboardName] = [];
    }
    dashboards[dashboardName].push({ name: scenarioName, data: combinedJson });
  });

  // Now, update the config for each dashboard with the collected properties and types
  Object.keys(dashboardPropertyTypes).forEach((dashboardName) => {
    const properties = Array.from(dashboardPropertyTypes[dashboardName].keys());
    if (properties.length > 0) {
      const isNamedDashboard = namedDashboards.has(dashboardName);

      // Create a map for easy lookup of existing properties
      const existingPropertiesMap = new Map();
      (config[dashboardName]?.properties || []).forEach((prop) => {
        existingPropertiesMap.set(prop.name, { ...prop });
      });

      if (isNamedDashboard) {
        // For named dashboards, only update min and max of existing properties
        properties.forEach((prop) => {
          if (existingPropertiesMap.has(prop)) {
            const existingProp = existingPropertiesMap.get(prop);

            // Get the type from the propertyTypes map
            const propType =
              dashboardPropertyTypes[dashboardName].get(prop) || existingProp.type;

            const numericPropStats =
              dashboardNumericProperties[dashboardName].get(prop);

            // Update existing property's type, min, and max
            existingProp.type = propType;
            existingProp.min =
              numericPropStats?.min ?? existingProp.min ?? 0;
            existingProp.max =
              numericPropStats?.max ?? existingProp.max ?? 100;
          }
        });

        // Update the dashboard configuration with the modified properties
        setConfig((prevConfig) => ({
          ...prevConfig,
          [dashboardName]: {
            ...prevConfig[dashboardName],
            properties: Array.from(existingPropertiesMap.values()),
            // Retain other existing configurations
          },
        }));
      } else {
        // For unnamed dashboards, add new properties
        // Merge new properties with existing ones
        const updatedProperties = properties.map((prop, index) => {
          const defaultProp = existingPropertiesMap.get(prop);

          // Get the type from the propertyTypes map
          const propType =
            dashboardPropertyTypes[dashboardName].get(prop) || "unknown";

          const numericPropStats =
            dashboardNumericProperties[dashboardName].get(prop);

          // Merge existing property settings with new ones
          return {
            name: prop,
            type: propType,
            color: defaultProp?.color || generateDefaultColor(index),
            min: numericPropStats?.min ?? defaultProp?.min ?? 0,
            max: numericPropStats?.max ?? defaultProp?.max ?? 100,
            total: defaultProp?.total || 1000,
            colorScale: defaultProp?.colorScale || "Viridis",
          };
        });

        // Combine existing properties with updated ones, avoiding duplicates
        const combinedPropertiesMap = new Map();
        updatedProperties.forEach((prop) => {
          combinedPropertiesMap.set(prop.name, prop);
        });
        (config[dashboardName]?.properties || []).forEach((prop) => {
          if (!combinedPropertiesMap.has(prop.name)) {
            combinedPropertiesMap.set(prop.name, prop);
          }
        });
        const combinedProperties = Array.from(combinedPropertiesMap.values());

        // Similarly, merge chartProperties
        const existingChartProperties =
          config[dashboardName]?.chartProperties || [];
        const newChartProperties = existingChartProperties.slice(); // Copy existing
        properties.forEach((prop) => {
          if (!newChartProperties.includes(prop)) {
            newChartProperties.push(prop);
          }
        });

        // Update the dashboard configuration with the merged properties
        setConfig((prevConfig) => ({
          ...prevConfig,
          [dashboardName]: {
            ...prevConfig[dashboardName],
            properties: combinedProperties,
            chartProperties: newChartProperties,
            selectedProperty:
              prevConfig[dashboardName].selectedProperty ||
              combinedProperties[0]?.name ||
              null,
            // Retain other existing configurations
            icon: prevConfig[dashboardName].icon,
            scenarioText: prevConfig[dashboardName].scenarioText,
            // Include other dashboard-specific configurations as needed
          },
        }));
      }
    }
  });

  // Return the updated dashboards object
  return dashboards;
};
