import * as d3 from 'd3';

export const renderMobilityDayTripGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );

  const dataTypes = ['wkd', 'wknd'];
  const mobilityData = {
    wkd: {
      transit: 0,
      walk: 0,
      bike: 0,
      vehicle: 0,
    },
    wknd: {
      transit: 0,
      walk: 0,
      bike: 0,
      vehicle: 0,
    }
  };

  filteredFeatures.forEach((feature) => {
    dataTypes.forEach(type => {
      mobilityData[type].transit += feature.properties?.[`${type}_transit_[People][Daily]`] || 0;
      mobilityData[type].walk += feature.properties?.[`${type}_walk_[People][Daily]`] || 0;
      mobilityData[type].bike += feature.properties?.[`${type}_bike_[People][Daily]`] || 0;
      mobilityData[type].vehicle += feature.properties?.[`${type}_vehicle_[People][Daily]`] || 0;
    });
  });

  const keys = Object.keys(mobilityData.wkd).filter(key => mobilityData.wkd[key] > 0 || mobilityData.wknd[key] > 0);

  if (!keys.length) {
    console.error("No valid keys found for mobilityData:", mobilityData);
    return;
  }

  const margin = { top: 20, right: 80, bottom: 100, left: 20 };
  const width = (dashboardConfig?.chartSettings?.width || 350) - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 200) - margin.top - margin.bottom; // Height adjusted to fit both bars

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Color scale
  const colorScale = d3.scaleOrdinal()
    .domain(['transit', 'walk', 'bike', 'vehicle'])
    .range(['#0263b9', '#f24726', '#fac710', '#2d9bf0']);

  // Stacked bar data for both weekday and weekend
  const totalWkdPeopleCount = d3.sum(Object.values(mobilityData.wkd));
  const totalWkndPeopleCount = d3.sum(Object.values(mobilityData.wknd));

  const maxTotal = Math.max(totalWkdPeopleCount, totalWkndPeopleCount);

  const x = d3.scaleLinear()
    .domain([0, maxTotal])
    .nice()
    .range([0, width]);

  const barHeight = height / 3; // Height for each bar with space between them

  // Weekday stacked bar
  let accumulatedWkdValue = 0;

  svg.selectAll('rect.wkd')
    .data(keys)
    .enter().append('rect')
    .attr('class', 'wkd')
    .attr('x', (d) => {
      const xValue = accumulatedWkdValue;
      accumulatedWkdValue += mobilityData.wkd[d];
      return x(xValue);
    })
    .attr('y', 0)
    .attr('height', barHeight)
    .attr('width', d => x(mobilityData.wkd[d]) - x(0))
    .attr('fill', d => colorScale(d));

  // Weekend stacked bar
  let accumulatedWkndValue = 0;

  svg.selectAll('rect.wknd')
    .data(keys)
    .enter().append('rect')
    .attr('class', 'wknd')
    .attr('x', (d) => {
      const xValue = accumulatedWkndValue;
      accumulatedWkndValue += mobilityData.wknd[d];
      return x(xValue);
    })
    .attr('y', barHeight + 30)
    .attr('height', barHeight)
    .attr('width', d => x(mobilityData.wknd[d]) - x(0))
    .attr('fill', d => colorScale(d));

  // Add text label for weekday stacked bar
  svg.append('text')
    .attr('x', x(totalWkdPeopleCount) + 5)
    .attr('y', barHeight / 2)
    .attr('dy', '.35em')
    .style('font-size', '12px')
    .style('fill', '#000')
    .text(`Weekday: ${d3.format('.2s')(totalWkdPeopleCount)} `);

  // Add text label for weekend stacked bar
  svg.append('text')
    .attr('x', x(totalWkndPeopleCount) + 5)
    .attr('y', barHeight + 30 + barHeight / 2)
    .attr('dy', '.35em')
    .style('font-size', '12px')
    .style('fill', '#000')
    .text(`Weekend: ${d3.format('.2s')(totalWkndPeopleCount)} `);

  // X-axis
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${barHeight * 2 + 30})`)
    .call(d3.axisBottom(x).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.x-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

  // X-axis label
  svg.append("text")
    .attr("class", "x-axis-label")
    .attr("x", width)
    .attr("y", barHeight * 2 + 60)
    .attr("text-anchor", "middle")
    .style("font-size", "10px")
    .text("People");

  // Legend
  const legend = svg.append('g')
    .attr('transform', `translate(0, ${barHeight * 2 + 80})`);

  const categories = ['transit', 'walk', 'bike', 'vehicle'];
  const legendXOffset = 20;
  const legendYOffset = 10;

  categories.forEach((category, i) => {
    const row = Math.floor(i / 2);
    const col = i % 2;

    legend.append('rect')
      .attr('x', col * 100)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(category));

    legend.append('text')
      .attr('x', col * 100 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(category)
      .style('font-size', '12px')
      .style('fill', '#000');
  });
};
