// ScenarioCard.jsx
import React, { useState, useEffect, useMemo, useRef } from "react";
import {
  Paper,
  List,
  ListItem,
  ListItemText,
  Button,
  Box,
  Divider,
  Typography,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { debounce } from "lodash";
import DropdownButton from "./DropdownButton";
import ScenarioStackedBarChart from "./ScenarioStackedBarChart";

function ScenarioCard({
  geoJsonFilesByDashboard,
  setSelectedGeoJson,
  selectedGeoJson,
  onOpenFileUploadCard,
  setSelectedDashboard,
  selectedDashboard,
  config,
  scenarioData,
  setScenarioData,
}) {
  const dashboards = useMemo(
    () => Object.keys(geoJsonFilesByDashboard),
    [geoJsonFilesByDashboard]
  );

  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [selectedDashboardInternal, setSelectedDashboardInternal] = useState(
    dashboards[0] || ""
  );
  const [items, setItems] = useState(
    geoJsonFilesByDashboard[selectedDashboardInternal] || []
  );
  const [displayItems, setDisplayItems] = useState([]);
  const [isProportional, setIsProportional] = useState(false);
  const dragImageRef = useRef(null);

  const truncateFileName = (fileName, maxLength = 30) => {
    const nameWithoutExtension = fileName.replace(/\.geojson$/i, "");
    const nameWithSpaces = nameWithoutExtension.replace(/_/g, " ");
    if (nameWithSpaces.length <= maxLength) return nameWithSpaces;
    const start = nameWithSpaces.slice(0, Math.floor(maxLength / 2));
    const end = nameWithSpaces.slice(-Math.floor(maxLength / 2));
    return `${start}...${end}`;
  };

  useEffect(() => {
    if (
      dashboards.length > 0 &&
      !dashboards.includes(selectedDashboardInternal)
    ) {
      setSelectedDashboardInternal(dashboards[0]);
      setSelectedDashboard(dashboards[0]);
    } else if (dashboards.length === 0) {
      setSelectedDashboardInternal("");
      setSelectedGeoJson(null);
    }
  }, [
    dashboards,
    selectedDashboardInternal,
    setSelectedDashboard,
    setSelectedGeoJson,
  ]);

  useEffect(() => {
    const updateItems = () => {
      const newItems = geoJsonFilesByDashboard[selectedDashboardInternal] || [];
      setItems(newItems);
      setDisplayItems(newItems);
    };

    if (selectedDashboardInternal) {
      updateItems();
    }
  }, [geoJsonFilesByDashboard, selectedDashboardInternal]);

  useEffect(() => {
    if (
      selectedGeoJson &&
      !geoJsonFilesByDashboard[selectedDashboardInternal]?.some(
        (file) => file.data === selectedGeoJson
      )
    ) {
      const newScenario =
        geoJsonFilesByDashboard[selectedDashboardInternal]?.[0]?.data;
      if (newScenario) {
        setSelectedGeoJson(newScenario);
      } else {
        setSelectedGeoJson(null);
      }
    }
  }, [
    selectedDashboardInternal,
    selectedGeoJson,
    geoJsonFilesByDashboard,
    setSelectedGeoJson,
  ]);

  const handleDashboardChange = debounce((dashboard) => {
    setSelectedDashboardInternal(dashboard);
    setSelectedDashboard(dashboard);
  }, 300);

  const getScenarioPropertiesForDashboard = (dashboard) => {
    const properties = config[dashboard]?.scenarioProperties || [];
    return properties;
  };

  const getScenarioColorsForDashboard = (dashboard) => {
    const colors = config[dashboard]?.scenarioColors || [];
    return colors;
  };

  const sortedItemsWithTotals = useMemo(() => {
    return items
      .map((file, index) => {
        const scenarioProperties = getScenarioPropertiesForDashboard(
          selectedDashboardInternal
        );

        const totalSum = scenarioProperties.reduce((sum, prop) => {
          return sum + (file.data?.features?.[0]?.properties?.[prop] || 0);
        }, 0);

        return { file, totalSum, index };
      })
      .sort((a, b) => b.totalSum - a.totalSum);
  }, [items, selectedDashboardInternal, config]);

  const maxTotal = useMemo(() => {
    if (!sortedItemsWithTotals.length) return 0;
    const max = sortedItemsWithTotals[0].totalSum;
    return max;
  }, [sortedItemsWithTotals]);

  useEffect(() => {
    if (sortedItemsWithTotals.length) {
      setDisplayItems(sortedItemsWithTotals.map((item) => item.file));
    }
  }, [sortedItemsWithTotals]);

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        width: "400px",
        position: "absolute",
        top: "20px",
        left: "20px",
        display: "flex",
        flexDirection: "column",
        maxHeight: "calc(100vh - 40px)",
      }}
    >
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <img
          src={`${process.env.PUBLIC_URL}/Urbano-Viewer-Logo.png`}
          alt="Urbano Viewer"
          style={{
            width: "200px",
            height: "auto",
            padding: "10px 10px",
          }}
        />
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={onOpenFileUploadCard}
          >
            + Data
          </Button>
          <DropdownButton
            config={config}
            selectedDashboard={selectedDashboardInternal}
            setSelectedDashboard={(dashboard) => {
              setSelectedDashboardInternal(dashboard);
              handleDashboardChange(dashboard);
            }}
            availableOptions={dashboards}
          />
        </Box>
      </Box>

      <Divider style={{ margin: "5px" }} />

      <Typography
        variant="caption"
        style={{ marginLeft: "10px", marginTop: "5px", marginBottom: "5px" }}
      >
        {config[selectedDashboardInternal]?.scenarioText}
      </Typography>

      <Box style={{ flexGrow: 1, overflowY: "auto", overflowX: "hidden" }}>
        <List>
          {displayItems.map((file, index) => (
            <React.Fragment key={file.name + "-" + index}>
              <ListItem
                button
                draggable
                onClick={() => {
                  if (selectedGeoJson !== file.data) {
                    setSelectedGeoJson(file.data);
                  }
                }}
                style={{
                  cursor: "move",
                  borderRadius: "8px",
                  backgroundColor:
                    selectedGeoJson === file.data ? "white" : "inherit",
                  border:
                    selectedGeoJson === file.data
                      ? "2px solid lightblue"
                      : "none",
                  padding: "8px 16px",
                }}
              >
                <Box display="flex" alignItems="center" width="100%">
                  <DragIndicatorIcon
                    sx={{
                      cursor: "grab",
                      marginRight: "8px",
                      color: "#757575",
                    }}
                  />
                  <Box display="flex" flexDirection="column" width="100%">
                    <ListItemText primary={truncateFileName(file.name)} />

                    {/* Stacked Bar for the first 5 properties */}
                    <ScenarioStackedBarChart
                      properties={getScenarioPropertiesForDashboard(selectedDashboardInternal)
                        .slice(0, selectedDashboard === "EnergyBuilding" ? 5 : undefined) // Slice only if EnergyBuilding
                        .map((prop, idx) => ({
                          name: prop,
                          total: file.data?.features?.[0]?.properties?.[prop] || 0,
                          color: getScenarioColorsForDashboard(selectedDashboardInternal)[idx] || "#000000",
                        }))}
                      mode={isProportional ? "proportional" : "absolute"}
                      maxTotal={maxTotal}
                    />

                    {/* Separate PV bar, could add more for other scenarios like this */}
                    {selectedDashboard === "EnergyBuilding" && (
                      <ScenarioStackedBarChart
                        properties={[
                          {
                            name: "PV",
                            total:
                              file.data?.features?.[0]?.properties?.[
                              "Sum_PV[KWh][Yr]"
                              ] || 0,
                            color: config.EnergyBuilding.scenarioColors[5],
                          },
                        ]}
                        mode={isProportional ? "proportional" : "absolute"}
                        maxTotal={maxTotal}
                        customHeight={24} // Set to half height for PV bar
                      />
                    )}
                  </Box>
                </Box>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Box>
    </Paper>
  );
}

export default ScenarioCard;
