import React, { useEffect, useRef } from "react";
import MapboxGeocoder from "@mapbox/mapbox-gl-geocoder";
import "mapbox-gl/dist/mapbox-gl.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import mapboxgl from "mapbox-gl"; // Ensure you import this to use the marker
import index from "../index.css";

function SearchBar({ mapRef, isGeocoderAdded, setIsGeocoderAdded }) {
    const geocoderContainer = useRef(null);
    const markerRef = useRef(null);
    const geocoderRef = useRef(null);
    const initializedRef = useRef(false); // Ref to track initialization
  
    useEffect(() => {
      if (initializedRef.current) {
        return;
      }
  
      if (!mapRef.current || !geocoderContainer.current) {
        return;
      }
  
      initializedRef.current = true; // Set to true after first initialization
  
      const geocoder = new MapboxGeocoder({
        accessToken: process.env.REACT_APP_MAPBOX_TOKEN,
        mapboxgl: mapboxgl,
        flyTo: false,
      });
  
      geocoder.addTo(geocoderContainer.current);
      geocoderRef.current = geocoder;
  
      geocoder.on('result', (e) => {
        const { result } = e;
        const [lng, lat] = result.center;
  
        if (markerRef.current) {
          markerRef.current.remove();
        }
  
        markerRef.current = new mapboxgl.Marker()
          .setLngLat([lng, lat])
          .addTo(mapRef.current);
  
        mapRef.current.flyTo({
          center: [lng, lat],
          zoom: 14,
          essential: true,
        });
      });
  
      // Cleanup function
      return () => {
        if (geocoderRef.current) {
          geocoderRef.current.clear();
          geocoderRef.current = null;
        }
      };
    }, [mapRef]);
    // Note: Including mapRef in the dependency array is fine because changes to mapRef won't cause re-execution due to the early return.
  
    return (
      <div
        ref={geocoderContainer}
        style={{
          width: '80%',
          marginLeft: '2px',
          zIndex: 1,
        }}
      />
    );
  }
  
  export default SearchBar;