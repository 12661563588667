import React, { useEffect, useState, useCallback, useRef } from "react";
import "../App.css";

function MapSelectionLogic({
  mapRef,
  geoJsonData = { features: [] }, // Default value for geoJsonData in case it is not provided
  currentScenario,
  selectedFeatures,
  setSelectedFeatures,
}) {
  const validGeoJsonData =
    geoJsonData && Array.isArray(geoJsonData.features)
      ? geoJsonData
      : { features: [] };

  const [isDragging, setIsDragging] = useState(false);
  const [dragPoints, setDragPoints] = useState({ start: null, end: null });
  const [selectionBoxStyle, setSelectionBoxStyle] = useState({
    display: "none",
    left: "0px",
    top: "0px",
    width: "0px",
    height: "0px",
  });
  const [isFirstShiftSelect, setIsFirstShiftSelect] = useState(true);

  // Ref to store the previous scenario for comparison
  const previousScenarioRef = useRef(currentScenario);

  // Ref to keep track of selected feature IDs
  const selectedFeatureIDs = useRef([]);

  // Effect to update selectedFeatureIDs whenever selectedFeatures changes
  useEffect(() => {
    selectedFeatureIDs.current = selectedFeatures.map(
      (feature) => feature.properties.ID_ESL
    );
  }, [selectedFeatures]);

  useEffect(() => {
    if (currentScenario && currentScenario !== previousScenarioRef.current) {
      // Filter the new scenario's features based on the selected feature IDs
      const filteredFeatures = currentScenario.features.filter((feature) =>
        selectedFeatureIDs.current.includes(feature.properties.ID_ESL)
      );

      // Update selected features with the filtered features
      setSelectedFeatures(filteredFeatures);

      // Ensure the map only displays the filtered (selected) features
      const source = mapRef.current?.getSource("geojson-source");
      if (source) {
        source.setData({
          type: "FeatureCollection",
          features: filteredFeatures, // Only show selected features
        });
      } else {
        console.warn("GeoJSON source is not available on the map");
      }

      // Update the previous scenario reference
      previousScenarioRef.current = currentScenario;
    }
  }, [currentScenario, mapRef, setSelectedFeatures]);

  const handleResetFeatures = useCallback(() => {
    setIsFirstShiftSelect(true);
    if (currentScenario) {
      setSelectedFeatures(currentScenario.features);
      if (mapRef.current && mapRef.current.getSource("geojson-source")) {
        mapRef.current.getSource("geojson-source").setData({
          type: "FeatureCollection",
          features: currentScenario.features, // Resets to all features
        });
      }
    }
  }, [mapRef, setSelectedFeatures, currentScenario]);

  const handleKeyUp = useCallback(
    (e) => {
      if (e.key === "Escape") {
        handleResetFeatures();
      }
    },
    [handleResetFeatures]
  );

  const handleMouseDown = useCallback(
    (e) => {
      if (!e.originalEvent || !mapRef.current) return;

      const { shiftKey, ctrlKey } = e.originalEvent;
      if (!(shiftKey || ctrlKey)) return;

      e.preventDefault();
      const point = e.point;
      if (!point) {
        console.warn("Mouse point data is not available");
        return;
      }

      setIsDragging(true);
      setDragPoints({ start: point, end: point });

      setSelectionBoxStyle({
        display: "block",
        left: `${point.x}px`,
        top: `${point.y}px`,
        width: "0px",
        height: "0px",
      });
    },
    [mapRef]
  );

  const handleMouseMove = useCallback(
    (e) => {
      if (!isDragging || !dragPoints.start) return;

      const currentEndPoint = e.point;
      if (!currentEndPoint) {
        console.warn("Mouse point data is not available during movement");
        return;
      }

      setDragPoints((prev) => ({ ...prev, end: currentEndPoint }));

      const minX = Math.min(dragPoints.start.x, currentEndPoint.x);
      const maxX = Math.max(dragPoints.start.x, currentEndPoint.x);
      const minY = Math.min(dragPoints.start.y, currentEndPoint.y);
      const maxY = Math.max(dragPoints.start.y, currentEndPoint.y);

      setSelectionBoxStyle({
        display: "block",
        left: `${minX}px`,
        top: `${minY}px`,
        width: `${maxX - minX}px`,
        height: `${maxY - minY}px`,
      });
    },
    [isDragging, dragPoints.start]
  );

  const handleMouseUp = useCallback(
    (e) => {
      if (
        !isDragging ||
        !dragPoints.start ||
        !dragPoints.end ||
        !mapRef.current
      )
        return;
      setIsDragging(false);

      const bbox = [
        mapRef.current.unproject([
          Math.min(dragPoints.start.x, dragPoints.end.x),
          Math.min(dragPoints.start.y, dragPoints.end.y),
        ]),
        mapRef.current.unproject([
          Math.max(dragPoints.start.x, dragPoints.end.x),
          Math.max(dragPoints.start.y, dragPoints.end.y),
        ]),
      ];

      if (!bbox[0] || !bbox[1]) {
        console.warn("Bounding box could not be calculated from points");
        return;
      }

      const [minLng, minLat] = bbox[0].toArray();
      const [maxLng, maxLat] = bbox[1].toArray();

      const isWithinBoundingBox = (coordinates) => {
        if (!coordinates || !Array.isArray(coordinates)) return false;
        return coordinates.some(
          ([lng, lat]) =>
            lng >= minLng && lng <= maxLng && lat >= maxLat && lat <= minLat
        );
      };

      const selectedFeatures = validGeoJsonData.features.filter((feature) => {
        if (!feature || !feature.geometry) {
          console.warn("Feature or its geometry is missing");
          return false;
        }

        const { type, coordinates } = feature.geometry;
        if (!coordinates) {
          console.warn(`Feature with ID ${feature.id} has no coordinates`);
          return false;
        }

        switch (type) {
          case "Point":
            const [lng, lat] = coordinates;
            return (
              lng >= minLng && lng <= maxLng && lat >= maxLat && lat <= minLat
            );
          case "Polygon":
            return coordinates.some((ring) => isWithinBoundingBox(ring));
          case "MultiPolygon":
            return coordinates.some((polygon) =>
              polygon.some((ring) => isWithinBoundingBox(ring))
            );
          case "LineString":
            return isWithinBoundingBox(coordinates);
          case "MultiLineString":
            return coordinates.some((line) => isWithinBoundingBox(line));
          default:
            console.warn(`Unsupported geometry type: ${type}`);
            return false;
        }
      });

      if (!e.originalEvent) {
        console.warn("Original event data is missing");
        return;
      }

      const { shiftKey, ctrlKey } = e.originalEvent;

      setSelectedFeatures((prevSelected = []) => {
        const prevSelectedIds = new Set(prevSelected.map((f) => f.id));

        if (ctrlKey) {
          //////console.log("Control key pressed - ", selectedFeatures);
          const deselectedFeatureIds = new Set(
            selectedFeatures.map((f) => f.id)
          );
          const remainingFeatures = prevSelected.filter(
            (f) => !deselectedFeatureIds.has(f.id)
          );

          if (remainingFeatures.length === 0) {
            handleResetFeatures(); // Reset if no features remain
          } else {
            const source = mapRef.current.getSource("geojson-source");
            if (source) {
              source.setData({
                type: "FeatureCollection",
                features: remainingFeatures,
              });
            } else {
              console.warn("GeoJSON source is not available on the map");
            }
          }

          setSelectionBoxStyle({ display: "none" });
          return remainingFeatures.length ? remainingFeatures : prevSelected;
        }

        if (shiftKey) {
          //////console.log("shift key pressed - ", selectedFeatures);
          if (isFirstShiftSelect) {
            setIsFirstShiftSelect(false);
            setSelectedFeatures([]);

            const source = mapRef.current.getSource("geojson-source");
            if (source) {
              source.setData({
                type: "FeatureCollection",
                features: selectedFeatures.length
                  ? selectedFeatures
                  : validGeoJsonData.features,
              });
            } else {
              console.warn("GeoJSON source is not available on the map");
            }
            return selectedFeatures.length ? selectedFeatures : prevSelected;
          } else {
            const newSelection = [
              ...prevSelected,
              ...selectedFeatures.filter((f) => !prevSelectedIds.has(f.id)),
            ];

            const source = mapRef.current.getSource("geojson-source");
            if (source) {
              source.setData({
                type: "FeatureCollection",
                features: newSelection.length
                  ? newSelection
                  : validGeoJsonData.features,
              });
            } else {
              console.warn("GeoJSON source is not available on the map");
            }

            return newSelection.length ? newSelection : prevSelected;
          }
        }

        handleResetFeatures();
        return prevSelected;
      });

      setSelectionBoxStyle({ display: "none" });
    },
    [
      isDragging,
      dragPoints,
      validGeoJsonData,
      mapRef,
      handleResetFeatures,
      isFirstShiftSelect,
    ]
  );

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.on("mousedown", handleMouseDown);
      mapRef.current.on("mousemove", handleMouseMove);
      mapRef.current.on("mouseup", handleMouseUp);

      document.addEventListener("keyup", handleKeyUp);
    } else {
      console.warn("Map reference is invalid during effect setup");
    }

    return () => {
      if (mapRef.current) {
        mapRef.current.off("mousedown", handleMouseDown);
        mapRef.current.off("mousemove", handleMouseMove);
        mapRef.current.off("mouseup", handleMouseUp);
      }
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [mapRef, handleMouseDown, handleMouseMove, handleMouseUp, handleKeyUp]);

  return <div className="bounding-box" style={selectionBoxStyle} />;
}

export default React.memo(MapSelectionLogic);
