import * as d3 from 'd3';

export const renderMobilityTimeTripGraph = (chartRef, selectedFeatures, dashboardConfig, useWeekendData = true) => {
  if (!Array.isArray(selectedFeatures)) {
    console.error("selectedFeatures is not an array:", selectedFeatures);
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature.geometry?.type !== 'Point'
  );

  const timeOfDay = useWeekendData ? "wknd" : "wkd";
  const timePeriods = ['Morning', 'Breakfast', 'Lunch', 'Afternoon', 'Dinner', 'Night'];

  // Initialize data structure for each time period
  const timeData = timePeriods.map(() => ({
    transit: 0,
    walk: 0,
    bike: 0,
    vehicle: 0,
  }));

  filteredFeatures.forEach((feature) => {
    timePeriods.forEach((period, index) => {
      timeData[index].transit += feature.properties?.[`${timeOfDay}_transit_[People][TOD]`]?.[index] || 0;
      timeData[index].walk += feature.properties?.[`${timeOfDay}_walk_[People][TOD]`]?.[index] || 0;
      timeData[index].bike += feature.properties?.[`${timeOfDay}_bike_[People][TOD]`]?.[index] || 0;
      timeData[index].vehicle += feature.properties?.[`${timeOfDay}_vehicle_[People][TOD]`]?.[index] || 0;
    });
  });

  // Use all keys explicitly to ensure they are all included in the legend
  const keys = ['transit', 'walk', 'bike', 'vehicle'];

  const margin = { top: 20, right: 20, bottom: 40, left: 60 };
  const width = (dashboardConfig?.chartSettings?.width || 350) - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 300) - margin.top - margin.bottom;

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom + 60) // Extra space for legend
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const y = d3.scaleBand()
    .domain(timePeriods)
    .range([0, height])
    .padding(0.1);

  const x = d3.scaleLinear()
    .domain([0, d3.max(timeData, d => d3.sum(keys.map(key => d[key])))])
    .nice()
    .range([0, width]);

  const colorScale = d3.scaleOrdinal()
    .domain(keys)
    .range(['#0263b9', '#f24726', '#fac710', '#2d9bf0']);

  const stackedData = d3.stack().keys(keys)(timeData);

  svg.selectAll('g.layer')
    .data(stackedData)
    .enter().append('g')
    .attr('class', 'layer')
    .attr('fill', d => colorScale(d.key))
    .selectAll('rect')
    .data(d => d)
    .enter().append('rect')
    .attr('y', (d, i) => y(timePeriods[i]))
    .attr('x', d => x(d[0]))
    .attr('width', d => x(d[1]) - x(d[0]))
    .attr('height', y.bandwidth());

  // Y-axis
  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y));

  // X-axis
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(5));

  // Legend (below the graph)
  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 40})`);

  const legendXOffset = 20;
  const legendYOffset = 10;

  keys.forEach((key, i) => {
    const row = Math.floor(i / 2);
    const col = i % 2;

    legend.append('rect')
      .attr('x', col * 100)
      .attr('y', row * 20)
      .attr('width', 10)
      .attr('height', 10)
      .attr('fill', colorScale(key));

    legend.append('text')
      .attr('x', col * 100 + legendXOffset)
      .attr('y', row * 20 + legendYOffset)
      .text(key)
      .style('font-size', '12px')
      .style('fill', '#000');
  });
};
