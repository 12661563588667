import React, { useState, useEffect } from 'react';
import Slider from '@mui/material/Slider';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

/**
 * Retrieves the configuration for a selected property within a dashboard.
 *
 * @param {Object} dashboardConfig - The overall configuration object for all dashboards.
 * @param {string} selectedDashboard - The key identifying the currently selected dashboard.
 * @param {string} selectedProperty - The name of the property whose configuration is being retrieved.
 * @returns {Object|null} The configuration object for the selected property, or null if not found.
 */
function getPropertyConfig(dashboardConfig, selectedDashboard, selectedProperty) {
  // Access the selected dashboard's configuration
  const dashboard = dashboardConfig[selectedDashboard];
  
  // Ensure the dashboard exists and has a properties array
  if (dashboard && Array.isArray(dashboard.properties)) {
    // Find and return the property configuration matching the selected property name
    return dashboard.properties.find((prop) => prop.name === selectedProperty);
  }
  
  // Return null if the dashboard or properties array is not found
  return null;
}

/**
 * TimeSliderCard component allows for visualizing data over time using a slider.
 *
 * Props:
 * - selectedGeoJson: The GeoJSON data currently selected.
 * - selectedDashboard: The key of the currently selected dashboard.
 * - selectedProperty: The name of the property to visualize over time.
 * - config: The overall configuration object for dashboards and their properties.
 * - onSliderChange: Callback function to notify parent components when the slider value changes.
 * - selectedIndex: The currently selected index value for the slider.
 */
const TimeSliderCard = ({
  selectedGeoJson,
  selectedDashboard,
  selectedProperty,
  config,
  onSliderChange,
  selectedIndex,
}) => {
  // State to keep track of the current slider index
  const [currentIndex, setCurrentIndex] = useState(0);
  
  // State to store the length of the array property
  const [arrayLength, setArrayLength] = useState(0);
  
  // State to determine if the selected property is an array
  const [isArrayProperty, setIsArrayProperty] = useState(false);

  /**
   * useEffect hook runs whenever selectedGeoJson, selectedProperty, selectedDashboard, or config changes.
   * It determines if the selected property is an array and sets up the slider accordingly.
   */
  useEffect(() => {
    // Retrieve the property configuration from the appropriate dashboard
    const selectedPropertyConfig = getPropertyConfig(
      config,
      selectedDashboard,
      selectedProperty
    );

    // If the property configuration is not found, log an error and exit
    if (!selectedPropertyConfig) {
      console.error(
        `No property configuration found for selected property: ${selectedProperty}`
      );
      return;
    }

    // Retrieve the data type from the property configuration
    const dataType = selectedPropertyConfig.type;

    // Check if the property type is an array
    if (dataType === 'array') {
      setIsArrayProperty(true); // Set flag indicating it's an array property
      let foundArray = false;    // Flag to indicate if an array was found in the data
      let arrayLen = 0;          // Variable to store the length of the array

      // Loop through each feature in the GeoJSON data to find the first array property
      for (let i = 0; i < selectedGeoJson.features.length; i++) {
        const feature = selectedGeoJson.features[i];
        let propertyValue = feature.properties[selectedProperty];

        // Check if the property value is already an array
        if (Array.isArray(propertyValue)) {
          arrayLen = propertyValue.length; // Get the length of the array
          foundArray = true;               // Set the flag indicating an array was found
          break;                           // Exit the loop as we found an array
        } 
        // If the property value is a string, attempt to parse it as JSON
        else if (typeof propertyValue === 'string') {
          try {
            const parsedValue = JSON.parse(propertyValue);
            // If parsing results in an array, use its length
            if (Array.isArray(parsedValue)) {
              arrayLen = parsedValue.length;
              foundArray = true;
              break;
            }
          } catch (error) {
            // Ignore JSON parsing errors and continue
          }
        }
      }

      // If an array was found, set the array length state
      if (foundArray) {
        setArrayLength(arrayLen);
      } else {
        // If no array was found, reset the flags and states
        setIsArrayProperty(false);
        setArrayLength(0);
      }
    } else {
      // If the property type is not an array, reset the flags and states
      setIsArrayProperty(false);
      setArrayLength(0);
    }
  }, [selectedGeoJson, selectedProperty, selectedDashboard, config]);

  /**
   * Handler function for when the slider value changes.
   *
   * @param {Event} event - The event object from the slider change.
   * @param {number} newValue - The new value of the slider.
   */
  const handleSliderChange = (event, newValue) => {
    setCurrentIndex(newValue); // Update the current index state
    onSliderChange(newValue); // Notify parent component of the change
  };

  // If the selected property is not an array, do not render the slider
  if (!isArrayProperty) {
    return null;
  }

  return (
    <Card
      sx={{
        position: 'absolute',             // Position the card absolutely within its container
        bottom: '30px',                   // Place it 30px from the bottom
        left: '50%',                      // Center it horizontally
        transform: 'translateX(-50%)',    // Adjust for perfect centering
        zIndex: 1000,                     // Ensure it appears above other elements
        padding: '10px',                  // Add padding inside the card
        width: '50%',                     // Set the width to 50% of the parent
        textAlign: 'center',              // Center-align the text
        backgroundColor: 'rgba(255, 255, 255, 1)', // Set a solid white background
      }}
    >
      {/* Title of the slider card */}
      <Typography variant="h6">Time Slider</Typography>
      
      {/* Container for the slider with horizontal padding */}
      <Box sx={{ width: '100%', padding: '0 20px' }}>
        <Slider
          value={currentIndex}               // Current value of the slider
          onChange={handleSliderChange}      // Handler for when the slider value changes
          min={0}                            // Minimum slider value
          max={arrayLength - 1}              // Maximum slider value based on array length
          step={1}                           // Slider moves in steps of 1
          marks                             // Display marks on the slider
          valueLabelDisplay="auto"          // Automatically display the value label
        />
      </Box>
    </Card>
  );
};

export default TimeSliderCard;
