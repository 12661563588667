import * as d3 from 'd3';

export const renderHistogramGraph = (data, chartRef, dashboardConfig, min, max, bincount) => {
  const numericData = data.filter(d => !isNaN(d));
  const margin = { top: 20, right: 30, bottom: 40, left: 50 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || 250) - margin.top - margin.bottom;

  // Clear any existing content
  d3.select(chartRef.current).selectAll('*').remove();

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  // Create the x scale using the min and max from settings
  const x = d3.scaleLinear()
    .domain([min ?? d3.min(numericData), max ?? d3.max(numericData)]) // Fall back to data-driven min/max if not provided
    .range([0, width]);

  // Generate the bins using the provided bincount
  const binGenerator = d3.bin()
    .domain(x.domain())
    .thresholds(bincount); // Use bincount from settings

  const bins = binGenerator(numericData);

  // Create the y scale
  const y = d3.scaleLinear()
    .domain([0, d3.max(bins, d => d.length)])
    .nice()
    .range([height, 0]);

  // Create the color scale
  const color = d3.scaleLinear()
    .domain([0, bins.length - 1])
    .range(["#b3cde3", "#011f4b"]);

  // Draw the bars
  svg.append('g')
    .selectAll('rect')
    .data(bins)
    .enter()
    .append('rect')
    .attr('x', d => x(d.x0))
    .attr('y', d => y(d.length))
    .attr('width', d => x(d.x1) - x(d.x0) - 1)
    .attr('height', d => height - y(d.length))
    .attr('fill', (d, i) => color(i));

  // Add the x-axis
  svg.append('g')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).ticks(8));

  // Add the y-axis
  svg.append('g')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  // Style the y-axis text
  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');
};
