import React, { useState, useEffect, useRef } from "react";
import { Paper, Box } from "@mui/material";
import ChartControls from "./ChartControls";
import ChartArea from "./ChartArea";
import "../App.css"; // Import the CSS file

const ChartsCard = ({ config, selectedDashboard, selectedFeatures }) => {
  const chartRef = useRef(null);
  const [selectedProperty, setSelectedProperty] = useState("");
  const [chartType, setChartType] = useState("Histogram");
  const [chartData, setChartData] = useState([]);
  const [endUseData, setEndUseData] = useState([]);
  const [fuelUseData, setFuelUseData] = useState([]);
  const [localSettings, setLocalSettings] = useState({
    min: 0,
    max: 100,
    bincount: 10,
  });

  // Effect to initialize settings based on config
  useEffect(() => {
    if (config && selectedDashboard) {
      const dashboardConfig = config[selectedDashboard];
      if (dashboardConfig && dashboardConfig.properties?.length > 0) {
        // Set the initial selected property and chart type from config
        setSelectedProperty(
          dashboardConfig.selectedProperty || dashboardConfig.properties[0].name
        );
        setChartType(dashboardConfig.chartSettings?.type || "Histogram");
      } else {
        console.error("No properties found in dashboardConfig.");
      }
    }
  }, [config, selectedDashboard]);

  // Update chart data when selectedProperty or selectedFeatures change
  useEffect(() => {
    if (selectedProperty && selectedFeatures?.length > 0) {
      const filteredFeatures = selectedFeatures.filter(
        (feature) => feature.geometry.type !== "Point"
      );
      const data = filteredFeatures
        .map((feature) =>
          feature.properties[selectedProperty] !== undefined
            ? feature.properties[selectedProperty]
            : null
        )
        .filter((value) => value !== null); // Filter out null values
      
      setChartData(data);
    } else {
      setChartData([]); // Clear the chart data if there are no features or selectedProperty
    }
  }, [selectedProperty, selectedFeatures]);

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        width: "400px",
        position: "absolute",
        bottom: "400px",
        right: "20px",
      }}
    >
      {/* Chart Controls for selecting properties and chart type */}
      <Box mt={2}>
        <ChartControls
          config={config}
          selectedDashboard={selectedDashboard}
          selectedProperty={selectedProperty}
          setSelectedProperty={setSelectedProperty}
          chartType={chartType}
          setChartType={setChartType}
          localSettings={localSettings} // Pass the local settings to ChartControls
          setLocalSettings={setLocalSettings} // Provide a way for ChartControls to update localSettings
          chartRef={chartRef} // Pass the ref to ChartControls for export functionality
        />
      </Box>

      {/* Chart Area where the actual chart is rendered */}
      <Box mt={2} ref={chartRef}>
        <ChartArea
          config={config}
          selectedDashboard={selectedDashboard}
          chartData={chartData}
          endUseData={endUseData}
          selectedFeatures={selectedFeatures}
          chartType={chartType}
          localSettings={localSettings} // Pass the local settings to ChartArea
        />
      </Box>
    </Paper>
  );
};

export default ChartsCard;
