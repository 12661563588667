import * as d3 from 'd3';

export const renderEnergySummaryGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  let floorArea = 0;
  let energyUse = 0;
  let elecProduced = 0;
  let carbonEmissions = 0;
  let buildingCnt = 0;

  // Ensure selectedFeatures is an array and iterate over it
  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.warn("Invalid or empty selectedFeatures:", selectedFeatures);
    return;
  }

  selectedFeatures.forEach((feature) => {
    const properties = feature.properties || {};
    // Sum additional properties for cards, ensuring they are valid numbers
    if (typeof properties["FloorArea[Sqm]"] === 'number') floorArea += properties["FloorArea[Sqm]"];
    if (typeof properties["EnergyUse[KWh][Yr]"] === 'number') energyUse += properties["EnergyUse[KWh][Yr]"];
    if (typeof properties["PV[KWh][Yr]"] === 'number') elecProduced += properties["PV[KWh][Yr]"];
    if (typeof properties["CO2[kg][Yr]"] === 'number') carbonEmissions += properties["CO2[kg][Yr]"];
    if (properties["SelectForSim_ESL"] === true) buildingCnt++;    
  });

  // Prepare data for the table
  const tableData = [
    { label: 'Buildings (#)', value: buildingCnt },
    { label: 'Floor Area (Sqm)', value: floorArea },
    { label: 'Energy Use (KWh/Yr)', value: energyUse },
    { label: 'Electricity Produced (KWh/Yr)', value: elecProduced },
    { label: 'Carbon Emissions (Kg/Yr)', value: carbonEmissions },
  ];

  // Clear the existing content if any before appending new elements
  d3.select(chartRef.current).selectAll('*').remove();

  // Set up dimensions
  const margin = { top: 5, right: 10, bottom: 50, left: 10 };
  const width = (dashboardConfig?.chartSettings?.width || 700) / 2 - margin.left - margin.right;
  const height = dashboardConfig?.chartSettings?.height || 250 - margin.top - margin.bottom;

  // Create a table container with dimensions
  const container = d3.select(chartRef.current)
    .append('div')
    .style('width', `${width + margin.left + margin.right}px`)
    .style('height', `${height + margin.top + margin.bottom}px`)
    .style('overflow', 'auto')  // Ensure table fits within the container
    .style('padding', `${margin.top}px ${margin.right}px ${margin.bottom}px ${margin.left}px`);

  // Create a table with some styling
  const table = container
    .append('table')
    .attr('class', 'data-table')
    .style('width', '100%')
    .style('border-collapse', 'collapse')
    .style('margin', '0 auto')
    .style('font-size', '16px')
    .style('text-align', 'left');

  // // Append table header with styling
  // table.append('thead').append('tr')
  //   .selectAll('th')
  //   .data(['Metric', 'Value'])
  //   .enter()
  //   .append('th')
  //   .text(d => d)
  //   .style('background-color', '#3c6dc4')
  //   .style('color', 'white')
  //   .style('padding', '6px')
  //   .style('border-bottom', '2px solid #ddd');

  // Append table body with data rows
  const tbody = table.append('tbody');

  tbody.selectAll('tr')
    .data(tableData)
    .enter()
    .append('tr')
    .each(function(d, i) {
      const row = d3.select(this);
      row.append('td')
        .text(d.label)
        .style('padding', '6px')
        .style('border-bottom', '1px solid #ddd');
      row.append('td')
        .text(d3.format(".2s")(d.value))
        .style('padding', '8px')
        .style('border-bottom', '1px solid #ddd')
        .style('text-align', 'right');
      
      // Add alternating row color
      if (i % 2 === 0) {
        row.style('background-color', '#f9f9f9');
      }
    });
};
