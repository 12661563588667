import React, { useEffect, useRef } from "react";
import chroma from "chroma-js";

/**
 * Generates color stops for a given range and color scale.
 */
export function generateColorStops(min, max, colorScale) {
  if (
    typeof min !== "number" ||
    typeof max !== "number" ||
    isNaN(min) ||
    isNaN(max)
  ) {
    console.error(`Invalid min or max values: min=${min}, max=${max}`);
    return [["#D3D3D3", "#D3D3D3"]];
  }

  if (!chroma.brewer[colorScale]) {
    console.error(`Invalid color scale: ${colorScale}`);
    return [["#D3D3D3", "#D3D3D3"]];
  }

  const scale = chroma.scale(colorScale).mode("lab").colors(10);
  const colorStops = [];

  for (let i = 0; i < scale.length; i++) {
    const value = min + (i * (max - min)) / (scale.length - 1);
    colorStops.push([value, scale[i]]);
  }

  return colorStops.sort((a, b) => a[0] - b[0]);
}

/**
 * Retrieves the configuration for a selected property.
 */
function getPropertyConfig(propertyConfig, selectedProperty) {
  if (propertyConfig && Array.isArray(propertyConfig.properties)) {
    return propertyConfig.properties.find(
      (prop) => prop.name === selectedProperty
    );
  }
  return null;
}

/**
 * Generates a color mapping for categorical data.
 */
function generateCategoryColors(categories, colorScale) {
  const scale = chroma.scale(colorScale).colors(categories.length);
  const categoryColors = {};
  categories.forEach((category, index) => {
    categoryColors[category] = scale[index];
  });
  return categoryColors;
}

/**
 * Updates the map's fill and line colors based on the selected property configuration.
 */
export function updateMapColors(
  map,
  selectedProperty,
  propertyConfig,
  legendMax,
  legendMin,
  trigger,
  selectedIndex,
  colorScale, // Existing parameter
  belowMinColor = "#808080", // New parameter with default value
  aboveMaxColor = "#FF69B4"  // New parameter with default value
) {
  const selectedPropertyConfig = getPropertyConfig(
    propertyConfig,
    selectedProperty
  );

  if (!selectedPropertyConfig) {
    console.error(
      `No property configuration found for selected property: ${selectedProperty}`
    );
    return;
  }

  const propertyType = selectedPropertyConfig.type;

  // Ensure colorScale passed from the component is used, falling back to the config value
  const finalColorScale = colorScale || selectedPropertyConfig.colorScale;

  // Check for the existence of required layers in the map
  const fillLayerExists = map.getLayer("geojson-fill-layer");
  const lineLayerExists = map.getLayer("geojson-line-layer");

  if (!fillLayerExists || !lineLayerExists) {
    console.error("Layer not found in the map.");
    return;
  }

  let fillColorExpression;

  if (propertyType === "number") {
    // Handle numerical properties
    let configMin = selectedPropertyConfig.min;
    let configMax = selectedPropertyConfig.max;

    let finalMin, finalMax;

    if (trigger === "legendChange") {
      finalMin =
        legendMin !== null && legendMin !== undefined ? legendMin : configMin;
      finalMax =
        legendMax !== null && legendMax !== undefined ? legendMax : configMax;
    } else {
      finalMin = configMin;
      finalMax = configMax;
    }

    if (
      finalMin === null ||
      finalMax === null ||
      isNaN(finalMin) ||
      isNaN(finalMax)
    ) {
      console.error("Invalid min or max values detected:", finalMin, finalMax);
      return;
    }

    const colorStops = generateColorStops(
      finalMin,
      finalMax,
      finalColorScale
    ) || [["#D3D3D3", "#D3D3D3"]];

    // Define outlier thresholds
    const lowerThreshold = finalMin - 10;
    const upperThreshold = finalMax + 10;

    fillColorExpression = [
      "case",
      // Handle null or undefined values
      ["==", ["get", selectedProperty], null],
      "#D3D3D3", // Default grey color
      // Low outliers (less than min - 10)
      ["<", ["get", selectedProperty], lowerThreshold],
      belowMinColor, // Use the passed belowMinColor
      // High outliers (greater than max + 10)
      [">", ["get", selectedProperty], upperThreshold],
      aboveMaxColor, // Use the passed aboveMaxColor
      // Values within range
      [
        "interpolate",
        ["linear"],
        ["to-number", ["get", selectedProperty]],
        ...colorStops.flat(),
      ],
    ];
  } else if (propertyType === "array") {
    // Handle array properties using selectedIndex
    const arrayIndex = selectedIndex !== undefined ? selectedIndex : 0;

    let configMin = selectedPropertyConfig.min;
    let configMax = selectedPropertyConfig.max;

    let finalMin, finalMax;

    if (trigger === "legendChange") {
      finalMin =
        legendMin !== null && legendMin !== undefined ? legendMin : configMin;
      finalMax =
        legendMax !== null && legendMax !== undefined ? legendMax : configMax;
    } else {
      finalMin = configMin;
      finalMax = configMax;
    }

    if (
      finalMin === null ||
      finalMax === null ||
      isNaN(finalMin) ||
      isNaN(finalMax)
    ) {
      console.error("Invalid min or max values detected:", finalMin, finalMax);
      return;
    }

    const colorStops = generateColorStops(
      finalMin,
      finalMax,
      finalColorScale
    ) || [["#D3D3D3", "#D3D3D3"]];

    // Define outlier thresholds
    const lowerThreshold = finalMin - 10;
    const upperThreshold = finalMax + 10;

    fillColorExpression = [
      "case",
      // Handle null or undefined values
      ["==", ["get", selectedProperty], null],
      "#D3D3D3", // Default grey color
      // Low outliers (less than min - 10)
      ["<", ["at", arrayIndex, ["get", selectedProperty]], lowerThreshold],
      belowMinColor, // Use the passed belowMinColor
      // High outliers (greater than max + 10)
      [">", ["at", arrayIndex, ["get", selectedProperty]], upperThreshold],
      aboveMaxColor, // Use the passed aboveMaxColor
      // Values within range
      [
        "interpolate",
        ["linear"],
        ["to-number", ["at", arrayIndex, ["get", selectedProperty]]],
        ...colorStops.flat(),
      ],
    ];
  } else if (propertyType === "category") {
    // No outlier handling for categorical properties
    const scale = selectedPropertyConfig.colorScale || "Set1";

    // Extract categories from the data
    const data = map.getSource("geojson-source")?._data;
    if (!data) {
      console.error("GeoJSON data not found in the map source.");
      return;
    }

    const features = data.features || [];
    const categoriesSet = new Set();
    features.forEach((feature) => {
      const value = feature.properties[selectedProperty];
      if (value !== null && value !== undefined) {
        categoriesSet.add(value);
      }
    });
    const categories = Array.from(categoriesSet);

    const categoryColors = generateCategoryColors(categories, scale);

    const matchExpression = ["match", ["get", selectedProperty]];
    categories.forEach((category) => {
      matchExpression.push(category, categoryColors[category]);
    });
    matchExpression.push("#D3D3D3"); // Default color

    fillColorExpression = matchExpression;
  } else {
    console.error(`Unsupported property type: ${propertyType}`);
    return;
  }

  // Apply the fill color expression to both fill and line layers
  map.setPaintProperty("geojson-fill-layer", "fill-color", fillColorExpression);
  map.setPaintProperty("geojson-line-layer", "line-color", fillColorExpression);
}

/**
 * React component for managing map color coding.
 */
const ColorCodingLogic = ({
  map,
  selectedProperty,
  propertyConfig,
  legendMax,
  legendMin,
  selectedIndex,
  colorScale,
  belowMinColor,   // New prop
  aboveMaxColor,   // New prop
}) => {
  const prevInputsRef = useRef({
    selectedProperty: null,
    min: null,
    max: null,
    colorScale: null,
    legendMax: null,
    legendMin: null,
    selectedIndex: null,
    belowMinColor: null, // Track previous belowMinColor
    aboveMaxColor: null, // Track previous aboveMaxColor
  });

  useEffect(() => {
    const {
      selectedProperty: prevSelectedProperty,
      legendMax: prevLegendMax,
      legendMin: prevLegendMin,
      selectedIndex: prevSelectedIndex,
      colorScale: prevColorScale,
      belowMinColor: prevBelowMinColor,   // Previous belowMinColor
      aboveMaxColor: prevAboveMaxColor,   // Previous aboveMaxColor
    } = prevInputsRef.current;

    const selectedPropertyConfig = getPropertyConfig(
      propertyConfig,
      selectedProperty
    );

    if (!selectedPropertyConfig) {
      console.error(
        `No property configuration found for selected property: ${selectedProperty}`
      );
      return;
    }

    const currentMin = legendMin;
    const currentMax = legendMax;
    const currentColorScale = colorScale;
    const currentBelowMinColor = belowMinColor;
    const currentAboveMaxColor = aboveMaxColor;

    const legendMinHasChanged =
      legendMin !== prevLegendMin && legendMin !== currentMin;
    const legendMaxHasChanged =
      legendMax !== prevLegendMax && legendMax !== currentMax;
    const selectedPropertyHasChanged =
      selectedProperty !== prevSelectedProperty;
    const selectedIndexHasChanged = selectedIndex !== prevSelectedIndex;
    const colorScaleHasChanged = currentColorScale !== prevColorScale;
    const belowMinColorHasChanged = currentBelowMinColor !== prevBelowMinColor;
    const aboveMaxColorHasChanged = currentAboveMaxColor !== prevAboveMaxColor;

    let trigger = null;

    if (selectedPropertyHasChanged) {
      trigger = "propertyChange";
    } else if (
      legendMinHasChanged ||
      legendMaxHasChanged ||
      belowMinColorHasChanged ||
      aboveMaxColorHasChanged
    ) {
      trigger = "legendChange";
    } else if (selectedIndexHasChanged) {
      trigger = "indexChange";
    } else if (colorScaleHasChanged) {
      trigger = "colorRampChange"; // Track color scale changes
    }

    if (trigger) {
      prevInputsRef.current = {
        selectedProperty,
        min: currentMin,
        max: currentMax,
        colorScale: currentColorScale,
        legendMax,
        legendMin,
        selectedIndex,
        belowMinColor: currentBelowMinColor, // Update stored belowMinColor
        aboveMaxColor: currentAboveMaxColor, // Update stored aboveMaxColor
      };

      if (!map || !map.getSource("geojson-source")) return;

      // Use previous colorScale if the trigger is an indexChange
      const colorScaleToUse =
        trigger === "indexChange" && prevColorScale
          ? prevColorScale
          : currentColorScale;

      updateMapColors(
        map,
        selectedProperty,
        propertyConfig,
        legendMax,
        legendMin,
        trigger,
        selectedIndex,
        colorScaleToUse,      // Pass color scale to updateMapColors
        belowMinColor,       // Pass belowMinColor
        aboveMaxColor        // Pass aboveMaxColor
      );
    }
  }, [
    map,
    selectedProperty,
    propertyConfig,
    legendMax,
    legendMin,
    selectedIndex,
    colorScale,
    belowMinColor,        // Add to dependencies
    aboveMaxColor,        // Add to dependencies
  ]);

  return null;
};

export default ColorCodingLogic;
