import React, { useState, useEffect } from "react";
import {
    Select,
    MenuItem,
    IconButton,
    OutlinedInput
} from "@mui/material";
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { blue } from "@mui/material/colors";
import {
    Home,
    Apartment,
    Bolt,
    DirectionsCar,
    DirectionsWalk,
    Co2,
    Speed
} from "@mui/icons-material";
import PropTypes from 'prop-types';

// Define custom styles for the Select component using @emotion/styled
const SelectWrapper = styled(Select)`
    display: flex;
    align-items: center;
    padding: 0;

    // Style for the select input within the Select component
    .MuiSelect-select {
        display: flex;
        align-items: center;
        padding: 0 0 0 8px; // Left padding for spacing
        min-height: auto;
    }

    // Style adjustments to remove default padding
    .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input {
        padding-right: 0 !important;
    }

    // Remove border styling for the input field
    fieldset {
        border: none;
    }

    // Change border color on hover and focus
    &:hover fieldset {
        border-color: ${blue[500]};
    }
    &.Mui-focused fieldset {
        border-color: ${blue[500]};
    }
`;

// Define custom styles for the IconButton component using @emotion/styled
const IconButtonStyled = styled(IconButton)`
    padding: 0; // Remove padding around the icon button
    color: #fff; // Set icon color to white
    background-color: #808080; // Default background color

    // Change background color on hover
    &:hover {
        background-color: ${blue[800]};
    }

    // Set width, height, and border radius for styling
    width: 32px;
    height: 32px;
    border-radius: 20%;
    margin-left: 0px;
    margin-right: 5px; // Add spacing between icon and text
`;

// Define custom styles for the MenuItem component
const MenuItemStyled = styled(MenuItem)`
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.spacing(1)}; // Add gap between icon and text
`;

// Additional styling for the dropdown menu using emotion CSS
const MenuStyled = css`
    position: relative;

    // Add a small triangle above the menu for visual enhancement
    &::before {
        content: '';
        position: absolute;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 8px solid ${({ theme }) => theme.palette.background.paper};
    }
`;

// Custom styling for the Paper component of the dropdown menu
const PaperStyled = css`
    margin-top: 15px; // Add margin above the dropdown
`;

// Mapping of icon names to actual Material-UI icon components
const iconComponents = {
    Home: <Home />,
    Bolt: <Bolt />,
    DirectionsCar: <DirectionsCar />,
    DirectionsWalk: <DirectionsWalk />,
    Co2: <Co2 />,
    Apartment: <Apartment />,   
    Speed: <Speed />
};

// Main DropdownButton component
const DropdownButton = ({
    config, // Configuration object containing available dashboards and their icons
    selectedDashboard, // Currently selected dashboard
    setSelectedDashboard, // Function to update the selected dashboard
    availableOptions // List of dashboards that should appear in the dropdown
}) => {
    // State to manage the currently selected option (icon)
    const [selectedOption, setSelectedOption] = useState(config[selectedDashboard]?.icon || config.DataDashboard.icon);

    // State to control the open/close status of the dropdown menu
    const [open, setOpen] = useState(false);

    // Effect to update the selected option whenever the selected dashboard changes
    useEffect(() => {
        // Update the selected option icon based on the current dashboard
        const option = config[selectedDashboard]?.icon || config.DataDashboard.icon;
        setSelectedOption(option);
    }, [selectedDashboard]);

    // Handle changes in the dropdown selection
    const handleChange = (event) => {
        const value = event.target.value; // Get the selected value (icon)
        setSelectedOption(value); // Update the selected option state

        // Find the corresponding dashboard based on the selected icon
        const newDashboard = Object.keys(config).find(key => config[key].icon === value) || 'DataDashboard';
        setSelectedDashboard(newDashboard); // Update the selected dashboard

        setOpen(false); // Close the dropdown
    };

    // Open the dropdown menu
    const handleOpen = () => {
        setOpen(true);
    };

    // Close the dropdown menu
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <SelectWrapper
            value={selectedOption} // Set the selected option for the dropdown
            onChange={handleChange} // Handle changes to the dropdown selection
            IconComponent={() => null} // Remove the default dropdown arrow
            input={<OutlinedInput notched={false} disableUnderline />} // Use an OutlinedInput with no notch or underline
            MenuProps={{
                PaperProps: { className: PaperStyled }, // Apply custom styles to the dropdown paper
                MenuListProps: { className: MenuStyled }, // Apply custom styles to the dropdown menu list
                open: open, // Control the open state of the dropdown
                onClose: handleClose, // Handle closing of the dropdown
            }}
            onOpen={handleOpen} // Handle opening of the dropdown
            renderValue={(value) => (
                <IconButtonStyled>
                    {iconComponents[value]} {/* Render the selected icon inside a styled button */}
                </IconButtonStyled>
            )}
        >
            {/* Generate MenuItem elements for each available option in the config */}
            {Object.keys(config)
                .filter(key => availableOptions.includes(key)) // Filter options based on availableOptions prop
                .map((key) => (
                    <MenuItemStyled key={key} value={config[key].icon}> {/* Use the icon as the value */}
                        {iconComponents[config[key].icon]} {/* Render the icon */}
                        {key} {/* Display the dashboard name */}
                    </MenuItemStyled>
                ))}
        </SelectWrapper>
    );
};

// Define prop types for the DropdownButton component
DropdownButton.propTypes = {
    selectedDashboard: PropTypes.string.isRequired, // Prop type validation for selectedDashboard
    setSelectedDashboard: PropTypes.func.isRequired, // Prop type validation for setSelectedDashboard
    availableOptions: PropTypes.array.isRequired, // Prop type validation for availableOptions
};

export default DropdownButton;
