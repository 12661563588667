import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Snackbar,
  IconButton,
  Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Overlay from "./Overlay";
import Editor from "@monaco-editor/react";
import defaultconfig from "../Config/dashboardConfigDefault.json";

const DashboardConfigEditor = ({ defaultConfig, onSave, onClose }) => {
  const localStorageKey = "dashboardConfig";
  const [configText, setConfigText] = useState(() => {
    const savedConfig = localStorage.getItem(localStorageKey);
    return savedConfig ? savedConfig : JSON.stringify(defaultconfig, null, 2);
  });
  
  const [error, setError] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  // Load from localStorage on component mount
  useEffect(() => {
    const savedConfig = localStorage.getItem(localStorageKey);
    if (savedConfig) {
      try {
        const parsedConfig = JSON.parse(savedConfig);
        setConfigText(JSON.stringify(parsedConfig, null, 2));
        onSave(parsedConfig);
      } catch (e) {
        // If saved config is invalid, use default
        handleRestoreDefault();
      }
    }
  }, []);

  const handleEditorChange = (value, event) => {
    setConfigText(value);
  };

  const handleSave = () => {
    try {
      const updatedConfig = JSON.parse(configText);
      // Save to localStorage
      localStorage.setItem(localStorageKey, configText);
      onSave(updatedConfig);
      setError(null);
      setSnackbarOpen(true);
    } catch (e) {
      setError("Invalid JSON format");
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleRestoreDefault = () => {
    // Use the imported defaultconfig instead of the prop
    const defaultConfigString = JSON.stringify(defaultconfig, null, 2);
    setConfigText(defaultConfigString);
    localStorage.setItem(localStorageKey, defaultConfigString);
    onSave(defaultconfig);
    setError(null);
    setSnackbarOpen(true);
  };

  const handleDownload = () => {
    const element = document.createElement("a");
    const file = new Blob([configText], { type: "application/json" });
    element.href = URL.createObjectURL(file);
    element.download = "dashboardConfig.json";
    document.body.appendChild(element);
    element.click();
    element.remove();
  };

  return (
    <div>
      <Overlay />
      <Card
        elevation={3}
        style={{
          padding: "20px",
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          width: "50%",
          height: "80%",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1000,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h5" component="div">
            Dashboard Configuration Editor
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>

        <CardContent
          style={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflow: "hidden",
            margin: "20px",
            border: "1px solid #ccc",
          }}
        >
          <div style={{ flex: 1, position: "relative" }}>
            <Editor
              height="100%"
              defaultLanguage="json"
              value={configText}
              onChange={handleEditorChange}
              options={{
                minimap: { enabled: false },
                wordWrap: "on",
                automaticLayout: true,
                scrollBeyondLastLine: false,
                folding: true,
                lineNumbers: "on",
              }}
            />
          </div>
          {error && (
            <Typography color="error" variant="body2" style={{ marginTop: "10px" }}>
              {error}
            </Typography>
          )}
        </CardContent>

        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Button variant="contained" color="secondary" onClick={handleRestoreDefault}>
            Restore Default
          </Button>
          <Button variant="contained" color="primary" onClick={handleDownload}>
            Download Config
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save Configuration
          </Button>
        </div>
      </Card>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="Configuration saved successfully!"
      />
    </div>
  );
};

export default DashboardConfigEditor;