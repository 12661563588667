import React, { useEffect, useState, useMemo, useRef } from "react";
import {
  Paper,
  Typography,
  TextField,
  Box,
  MenuItem,
  IconButton,
  ClickAwayListener,
  Button,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloseIcon from "@mui/icons-material/Close";
import LegendHistogram from "./LegendHistogram";
import { updateMapColors } from "./ColorCodingLogic"; // Function to update the map colors based on the selected property and legend settings

function LegendCard({
  selectedDashboard,
  selectedGeoJson,
  setSelectedProperty,
  selectedProperty,
  mapRef, // Reference to the map object
  config,
  setLegendMin,
  setLegendMax,
  legendMin,
  legendMax,
  setConfig, // Function to update the config object
  selectedIndex,
  colorScale,
  setColorScale,
  belowMinColor,
  setBelowMinColor,
  aboveMaxColor,
  setAboveMaxColor,
}) {
  // State for managing the list of properties, histogram data, settings visibility, and legend configuration
  const [currentProperties, setCurrentProperties] = useState([]);
  const [histogramData, setHistogramData] = useState([]);
  const [showSettings, setShowSettings] = useState(false);
  const [localSettings, setLocalSettings] = useState({
    min: 0,
    max: 100,
    bincount: 10,
  });
  // Temporary states for min, max, and bincount to validate user input
  const [tempMin, setTempMin] = useState(localSettings.min.toString());
  const [tempMax, setTempMax] = useState(localSettings.max.toString());
  const [tempBinCount, setTempBinCount] = useState(
    localSettings.bincount.toString()
  );

  const settingsRef = useRef(null); // Reference to the settings panel
  const [minValid, setMinValid] = useState(true); // Validation state for min value
  const [maxValid, setMaxValid] = useState(true); // Validation state for max value

  const [originalSettings, setOriginalSettings] = useState(null);
  const [originalColorScale, setOriginalColorScale] = useState(null);

  const colorRampOptions = [
    { value: "YlOrRd", label: "Yellow-Orange-Red" },
    { value: "YlGnBu", label: "Yellow-Green-Blue" },
    { value: "RdYlBu", label: "Red-Yellow-Blue" },
    { value: "RdYlGn", label: "Red-Yellow-Green" },
    { value: "Spectral", label: "Spectral" },
    { value: "Viridis", label: "Viridis" },
    { value: "Accent", label: "Accent" },
    { value: "Blues", label: "Blues" },
    { value: "Reds", label: "Reds" },
    { value: "Greens", label: "Greens" },
    { value: "Oranges", label: "Oranges" },
    { value: "GnBu", label: "Green-Blue" },
    { value: "RdBu", label: "Red-Blue" },
    { value: "RdPu", label: "Red-Purple" },
    { value: "BuPu", label: "Blue-Purple" },
    { value: "Paired", label: "Paired" },
    { value: "Pastel1", label: "Pastel1" },
    { value: "Pastel2", label: "Pastel2" },
    { value: "Set1", label: "Set1" },
    { value: "Set2", label: "Set2" },
    { value: "Set3", label: "Set3" },
  ];

  // Parses property names and replaces underscores and brackets with spaces
  const parsePropertyName = (name) => {
    const mainName = name.replace(/[_\[\]]/g, " ");
    const units = ""; // Setting units to an empty string as it's no longer parsed
    return { mainName, units };
  };

  // Updates local settings based on the selected property configuration
  const updateSettingsFromProperty = (propertyConfig) => {
    if (propertyConfig) {
      const min = validateMinMax(propertyConfig.min, 0); // Use 0 as default if min is undefined
      const max = validateMinMax(propertyConfig.max, 100); // Use 100 as default if max is undefined
      const bincount = propertyConfig.bincount || 10; // Default bincount to 10 if undefined

      const updatedSettings = {
        min,
        max,
        bincount,
      };

      setLocalSettings(updatedSettings);
      setColorScale(propertyConfig.colorScale || "YlGnBu"); // Default color scale if undefined
      setTempMin(min.toString());
      setTempMax(max.toString());
      setTempBinCount(bincount.toString());
    }
  };

  // Effect to update properties and settings when the selected dashboard or property changes
  useEffect(() => {
    const currentConfig = config[selectedDashboard];
    if (currentConfig && currentConfig.properties) {
      setCurrentProperties(currentConfig.properties);

      // Find configuration for the selected property; default to the first property if not found
      let selectedPropConfig = currentConfig.properties.find(
        (prop) => prop.name === selectedProperty
      );

      if (!selectedPropConfig) {
        selectedPropConfig = currentConfig.properties[0];
        setSelectedProperty(selectedPropConfig.name);
      }

      updateSettingsFromProperty(selectedPropConfig);
    }
  }, [selectedDashboard, selectedProperty, config, setSelectedProperty]);

  // Memoized histogram data to avoid recalculating unless dependencies change
  const histogramDataMemo = useMemo(() => {
    if (!selectedGeoJson || !selectedGeoJson.features) {
      console.warn("GeoJSON data is missing or improperly formatted.");
      return [];
    }
    // Extracts values for the selected property from all GeoJSON features
    return selectedGeoJson.features.map(
      (feature) => feature.properties[selectedProperty]
    );
  }, [selectedGeoJson, selectedProperty]);

  // Updates the histogram data when memoized data changes
  useEffect(() => {
    setHistogramData(histogramDataMemo);
  }, [histogramDataMemo]);

  // Validates min/max values, ensuring they are numbers or defaulting to fallback values
  const validateMinMax = (value, fallback) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      console.warn(`Invalid number '${value}', using fallback ${fallback}`);
      return fallback;
    }
    return numValue;
  };

  // Handler: Property Change
  const handlePropertyChange = (event) => {
    const newProperty = event.target.value;
    const propertyConfig = currentProperties.find(
      (prop) => prop.name === newProperty
    );
    setSelectedProperty(newProperty);
    updateSettingsFromProperty(propertyConfig);

    // Update map colors when the property changes
    updateMapColors(
      mapRef.current,
      newProperty,
      config[selectedDashboard],
      legendMax,
      legendMin,
      "propertyChange",
      selectedIndex,
      colorScale,
      belowMinColor, // Use current belowMinColor
      aboveMaxColor // Use current aboveMaxColor
    );
  };

  // Handler: Temporary Min Change
  const handleTempMinChange = (event) => {
    const newValue = event.target.value;
    setTempMin(newValue);

    const validatedMin = validateMinMax(newValue, localSettings.min);
    if (validatedMin >= localSettings.max) {
      setMinValid(false);
    } else {
      setMinValid(true);
      setLocalSettings((prevSettings) => ({
        ...prevSettings,
        min: validatedMin,
      }));
      setLegendMin(validatedMin);

      // Update map colors when the min value changes
      updateMapColors(
        mapRef.current,
        selectedProperty,
        config[selectedDashboard],
        legendMax,
        validatedMin,
        "legendChange",
        selectedIndex,
        colorScale,
        belowMinColor, // Use current belowMinColor
        aboveMaxColor // Use current aboveMaxColor
      );
    }
  };

  // Handler: Temporary Max Change
  const handleTempMaxChange = (event) => {
    const newValue = event.target.value;
    setTempMax(newValue);

    const validatedMax = validateMinMax(newValue, localSettings.max);
    if (validatedMax <= localSettings.min) {
      setMaxValid(false);
    } else {
      setMaxValid(true);
      setLocalSettings((prevSettings) => ({
        ...prevSettings,
        max: validatedMax,
      }));
      setLegendMax(validatedMax);

      //console.log("New max value:", colorScale, validatedMax);

      // Update map colors when the max value changes
      updateMapColors(
        mapRef.current,
        selectedProperty,
        config[selectedDashboard],
        validatedMax,
        legendMin,
        "legendChange",
        selectedIndex,
        colorScale,
        belowMinColor, // Use current belowMinColor
        aboveMaxColor
      );
    }
  };

  // Handler: Temporary Bin Count Change
  const handleTempBinCountChange = (event) => {
    const newValue = event.target.value;
    setTempBinCount(newValue);

    // Ensure bin count is at least 1; default to 10 if parsing fails
    const validatedBinCount = Math.max(1, parseInt(newValue, 10)) || 10;
    setLocalSettings((prevSettings) => ({
      ...prevSettings,
      bincount: validatedBinCount,
    }));
  };

  // Handler: Below Min Color Change
  const handleBelowMinColorChange = (event) => {
    setBelowMinColor(event.target.value);
  };

  // Handler: Above Max Color Change
  const handleAboveMaxColorChange = (event) => {
    setAboveMaxColor(event.target.value);
  };

  // Handler: Color Ramp Change
  const handleColorRampChange = (event) => {
    const selectedScale = event.target.value;

    setColorScale(selectedScale);
    // Update the map colors with the new color ramp
    updateMapColors(
      mapRef.current, // map
      selectedProperty, // selectedProperty
      config[selectedDashboard], // propertyConfig
      legendMax,
      legendMin,
      "colorRampChange",
      selectedIndex,
      belowMinColor, // Use current belowMinColor
      aboveMaxColor // Use current aboveMaxColor
    );
  };

  // Handler: Toggle Settings Panel
  const handleToggleSettings = () => {
    if (!showSettings) {
      // Settings panel is about to open, store the current settings
      setOriginalSettings({ ...localSettings });
      setOriginalColorScale(colorScale);
    } else {
      // Settings panel is about to close without saving, revert to original settings
      revertToOriginalSettings();
    }
    setShowSettings(!showSettings);
  };

  // Helper: Revert to Original Settings
  const revertToOriginalSettings = () => {
    if (originalSettings) {
      setLocalSettings(originalSettings);
      setColorScale(originalColorScale);
      setTempMin(originalSettings.min.toString());
      setTempMax(originalSettings.max.toString());
      setTempBinCount(originalSettings.bincount.toString());
      setLegendMin(originalSettings.min);
      setLegendMax(originalSettings.max);

      // Update map colors to reflect the original settings
      updateMapColors(
        mapRef.current,
        selectedProperty,
        config[selectedDashboard],
        originalSettings.max,
        originalSettings.min,
        "settingsClose",
        selectedIndex,
        originalColorScale, // Pass the originalColorScale
        belowMinColor, // Use current belowMinColor
        aboveMaxColor // Use current aboveMaxColor
      );
    }
  };

  // Handler: Click Away from Settings Panel
  const handleClickAway = () => {
    if (showSettings) {
      // Revert to original settings
      revertToOriginalSettings();
      setShowSettings(false);
    }
  };

  // Handler: Save Settings
  const handleSaveSettings = () => {
    setConfig((prevConfig) => {
      const updatedConfig = { ...prevConfig };
      const dashboardConfig = updatedConfig[selectedDashboard];

      if (dashboardConfig) {
        const propertyConfig = dashboardConfig.properties.find(
          (prop) => prop.name === selectedProperty
        );
        if (propertyConfig) {
          propertyConfig.min = localSettings.min;
          propertyConfig.max = localSettings.max;
          propertyConfig.colorScale = colorScale;
          propertyConfig.bincount = localSettings.bincount;
        }
      }

      return updatedConfig;
    });

    // Update original settings to the new saved settings
    setOriginalSettings({ ...localSettings });
    setOriginalColorScale(colorScale);

    //console.log("New settings saved for", selectedProperty);

    // Close the settings panel after saving
    setShowSettings(false);
  };

  // Handler: Reset to Default Settings
  const handleResetDefault = () => {
    const propertyConfig = currentProperties.find(
      (prop) => prop.name === selectedProperty
    );
    if (propertyConfig) {
      const defaultMin = validateMinMax(propertyConfig.min, 0); // Use 0 if undefined
      const defaultMax = validateMinMax(propertyConfig.max, 100); // Use 100 if undefined
      const defaultBinCount = propertyConfig.bincount || 10; // Use 10 if undefined

      setLocalSettings({
        min: defaultMin,
        max: defaultMax,
        bincount: defaultBinCount,
      });
      setTempMin(defaultMin.toString());
      setTempMax(defaultMax.toString());
      setTempBinCount(defaultBinCount.toString());
      setMinValid(true);
      setMaxValid(true);
      setLegendMin(defaultMin); // Update the parent min value
      setLegendMax(defaultMax); // Update the parent max value

      // Reset colors to defaults
      setBelowMinColor("#808080"); // Grey
      setAboveMaxColor("#FFC0CB"); // Pink

      // Update map colors when resetting to default values
      updateMapColors(
        mapRef.current,
        selectedProperty,
        config[selectedDashboard],
        defaultMax,
        defaultMin,
        "resetDefault",
        selectedIndex,
        colorScale, // Pass the current colorScale
        "#808080", // Reset belowMinColor to default Grey
        "#FFC0CB" // Reset aboveMaxColor to default Pink
      );
    }
  };

  // Handler: Fit Data
  const handleFitData = () => {
    // Find the data type of the selected property
    const property = config[selectedDashboard].properties.find(
      (prop) => prop.name === selectedProperty
    );

    if (!property) {
      console.error(
        `Property "${selectedProperty}" not found in the selected dashboard.`
      );
      return;
    }

    const datatype = property.type;

    let fitMin, fitMax;

    if (datatype === "array") {
      //console.log("Calculating min and max from array data");

      // Initialize min and max with Infinity and -Infinity
      fitMin = Infinity;
      fitMax = -Infinity;

      // Iterate through each data point
      for (const dataPoint of histogramData) {
        // Ensure the dataPoint is an array
        if (Array.isArray(dataPoint)) {
          for (const value of dataPoint) {
            if (typeof value === "number" && !isNaN(value)) {
              if (value < fitMin) fitMin = value;
              if (value > fitMax) fitMax = value;
            }
          }
        } else {
          console.warn(`Expected an array but received: ${typeof dataPoint}`);
        }
      }

      // Handle cases where no valid numbers were found
      if (fitMin === Infinity || fitMax === -Infinity) {
        console.error("No valid numerical data found in array.");
        return;
      }
    } else if (datatype === "number") {
      //console.log("Calculating min and max from numerical data");

      const validData = histogramData.filter(
        (value) => typeof value === "number" && !isNaN(value)
      );

      if (validData.length === 0) {
        console.error("No valid numerical data available.");
        return;
      }

      fitMin = Math.min(...validData);
      fitMax = Math.max(...validData);
    } else {
      console.error(`Unsupported data type: ${datatype}`);
      return;
    }

    // Round fitMin and fitMax to the nearest hundred
    fitMin = Math.round(fitMin / 100) * 100;
    fitMax = Math.round(fitMax / 100) * 100;

    // Update local settings and state
    setLocalSettings((prevSettings) => ({
      ...prevSettings,
      min: fitMin,
      max: fitMax,
    }));
    setTempMin(fitMin.toString());
    setTempMax(fitMax.toString());
    setLegendMin(fitMin);
    setLegendMax(fitMax);

    // Optionally, adjust colors based on new min and max
    updateMapColors(
      mapRef.current,
      selectedProperty,
      config[selectedDashboard],
      fitMax,
      fitMin,
      "fit",
      selectedIndex,
      colorScale, // Pass the current colorScale
      belowMinColor, // Use current belowMinColor
      aboveMaxColor // Use current aboveMaxColor
    );
  };

  return (
    <Paper
      elevation={3}
      style={{
        padding: "20px",
        position: "absolute",
        bottom: "30px",
        right: "20px",
        width: "400px",
        zIndex: 5,
      }}
    >

      {/* <Typography variant="h6">Legend</Typography> */}

      <Box display="flex" justifyContent="space-between" alignItems="center" marginTop={"15px"}>
        <TextField
          select
          label="Property"
          value={selectedProperty || ""}
          onChange={handlePropertyChange}
          fullWidth
        >
          {currentProperties.map((property) => {
            const { mainName } = parsePropertyName(property.name);
            return (
              <MenuItem key={property.name} value={property.name}>
                {mainName}
              </MenuItem>
            );
          })}
        </TextField>
        <IconButton onClick={handleToggleSettings} style={{marginLeft: "10px"}}>
          {showSettings ? <CloseIcon /> : <SettingsIcon />}
        </IconButton>
      </Box>

      <Box mt={4}>
        {selectedProperty && (
          <LegendHistogram
            data={histogramData}
            colorScale={colorScale}
            propertyConfig={currentProperties.find(
              (prop) => prop.name === selectedProperty
            )}
            xAxisLabel={`${parsePropertyName(selectedProperty).mainName}`}
            min={localSettings.min}
            max={localSettings.max}
            bincount={localSettings.bincount || 10}
            selectedIndex={selectedIndex}
          />
        )}
      </Box>

      {showSettings && (
        <ClickAwayListener
          mouseEvent="onMouseDown" // This will detect mouse down events
          onClickAway={handleClickAway}
        >
          <Paper
            ref={settingsRef}
            elevation={3}
            style={{
              position: "absolute",
              top: "-45%",
              right: "0%",
              width: "100%",
              padding: "10px",
              zIndex: 1100,
            }}
          >
            {/* Header */}
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h6">Settings</Typography>
              <IconButton onClick={handleToggleSettings}>
                <CloseIcon />
              </IconButton>
            </Box>

            {/* Temperature Fields */}
            <Box mt={2} display="flex" justifyContent="space-between">
              <TextField
                label="Min"
                type="number"
                value={tempMin}
                onChange={handleTempMinChange}
                error={!minValid}
                style={{ marginRight: "10px", flex: 1 }}
              />
              <TextField
                label="Max"
                type="number"
                value={tempMax}
                onChange={handleTempMaxChange}
                error={!maxValid}
                style={{ marginRight: "10px", flex: 1 }}
              />
              <TextField
                label="Bin Count"
                type="number"
                value={tempBinCount}
                onChange={handleTempBinCountChange}
                style={{ flex: 1 }}
              />
            </Box>

            {/* Color Ramp and Color Pickers */}
            <Box
              mt={2}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              {/* Below Min Color Picker */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                mr={2}
              >
                <input
                  type="color"
                  value={belowMinColor}
                  onChange={handleBelowMinColorChange}
                  style={{
                    border: "none",
                    width: "40px",
                    height: "40px",
                    padding: "0",
                    background: "none",
                    cursor: "pointer",
                  }}
                  aria-label="Select color for values below minimum"
                />
              </Box>

              {/* Color Ramp Dropdown */}
              <TextField
                select
                label="Color Ramp"
                value={colorScale || ""}
                onChange={handleColorRampChange}
                style={{ flex: 1 }}
              >
                {colorRampOptions.map((ramp) => (
                  <MenuItem key={ramp.value} value={ramp.value}>
                    {ramp.label}
                  </MenuItem>
                ))}
              </TextField>

              {/* Above Max Color Picker */}
              <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                ml={2}
              >
                <input
                  type="color"
                  value={aboveMaxColor}
                  onChange={handleAboveMaxColorChange}
                  style={{
                    border: "none",
                    width: "40px",
                    height: "40px",
                    padding: "0",
                    background: "none",
                    cursor: "pointer",
                  }}
                  aria-label="Select color for values above maximum"
                />
              </Box>
            </Box>

            {/* Action Buttons */}
            <Box mt={2} display="flex" justifyContent="center">
              <Button
                onClick={handleResetDefault}
                color="primary"
                variant="outlined"
              >
                Reset Default
              </Button>
              <Button
                onClick={handleFitData}
                color="secondary"
                variant="outlined"
                style={{ marginLeft: "10px" }}
              >
                Fit Data
              </Button>
              <Button
                onClick={handleSaveSettings}
                color="success"
                variant="outlined"
                style={{ marginLeft: "10px" }}
              >
                Save
              </Button>
            </Box>
          </Paper>
        </ClickAwayListener>
      )}
    </Paper>
  );
}

export default LegendCard;
