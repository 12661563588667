import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';

/**
 * ScenarioStackedBarChart Component
 * 
 * This component renders a stacked bar chart using D3 within a React application.
 * It displays different properties with their respective totals, colors, and additional
 * information such as the total sum and percentage relative to the largest scenario.
 * 
 * Props:
 * - properties: Array of property objects with `name`, `total`, and `color`.
 * - mode: String indicating the scaling mode ('absolute' or 'proportional'). Defaults to 'absolute'.
 * - maxTotal: Number representing the maximum total value from the parent component.
 */
const ScenarioStackedBarChart = ({ properties, mode = 'absolute', maxTotal, customHeight = 30 }) => {
  // Reference to the SVG DOM element
  const ref = useRef();

  // Formatter for numbers with commas (e.g., 1,000)
  const formatNumberWithCommas = d3.format(',');

  /**
   * Formats numbers with 'K' for thousands and 'M' for millions.
   * @param {number} num - The number to format.
   * @returns {string} - Formatted number string.
   */
  const formatNumber = (num) => {
    if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'K';
    }
    return num.toString();
  };

  // useEffect hook runs after the component mounts and whenever dependencies change
  useEffect(() => {
    // If properties are empty, do not render the chart
    if (!properties || properties.length === 0) {
      return;
    }

    // Determine if properties contain valid totals
    const validProperties = properties.filter((prop) => prop.total !== undefined && prop.total > 0);
    if (validProperties.length === 0) {
      return;
    }

    // Reference to use either the filtered validProperties or the passed-in properties
    const data = validProperties;

    // Calculate the sum of all totals in the data
    const totalSum = d3.sum(data, d => d.total);

    // The maximum total value provided by the parent component
    const largestScenarioTotal = maxTotal;

    // Define margins around the SVG
    const margin = { top: 10, right: 40, bottom: 10, left: 0 };

    // Calculate the width and height of the SVG canvas
    const width = 300 - margin.left - margin.right; // Total width minus left and right margins
    const height = customHeight - margin.top - margin.bottom; // Total height minus top and bottom margins

    // Position to place the percentage text at the far right end
    const percentageRightEnd = width + margin.right;

    // Small gap between the end of the bar and the total sum text
    const gap = 5;

    // Select the SVG container using the ref and clear any existing content
    const svgContainer = d3.select(ref.current);
    svgContainer.selectAll('*').remove();

    /**
     * Create a tooltip div that is hidden by default.
     * This tooltip will appear when hovering over the bars.
     */
    const tooltip = d3.select('body')
      .append('div')
      .style('position', 'absolute')
      .style('background', 'white')
      .style('padding', '5px 10px')
      .style('border', '1px solid #ccc')
      .style('border-radius', '5px')
      .style('pointer-events', 'none') // Prevent tooltip from capturing mouse events
      .style('opacity', 0); // Initially hidden

    // Append an SVG group element to apply margins
    const svg = svgContainer
      .attr('width', width + margin.left + margin.right) // Set SVG width
      .attr('height', height + margin.top + margin.bottom) // Set SVG height
      .append('g') // Append a group element
      .attr('transform', `translate(${margin.left},${margin.top})`); // Apply margins

    /**
     * Define the x-axis scale based on the selected mode.
     * - 'absolute': Scales from 0 to the largest scenario total.
     * - 'proportional': Scales from 0 to 1, representing proportions.
     */
    const x = d3.scaleLinear()
      .domain(mode === 'absolute' ? [0, largestScenarioTotal] : [0, 1])
      .range([0, width - 60]); // Reserve 60 pixels for total sum and percentage

    // Variable to keep track of the cumulative position for stacking bars
    let cumulative = 0;

    /**
     * Append rectangles (bars) to the SVG for each property in the data.
     * The width of each bar is determined by the mode.
     */
    svg.selectAll('rect')
      .data(data)
      .enter()
      .append('rect')
      .attr('x', d => {
        const xPos = x(cumulative); // Calculate x position based on cumulative value
        // Update cumulative for the next bar
        cumulative += mode === 'absolute' ? d.total : (d.total / totalSum);
        return xPos;
      })
      .attr('y', 0) // Align bars to the top
      .attr('width', d => mode === 'absolute' ? x(d.total) : x(d.total / totalSum)) // Width based on mode
      .attr('height', height) // Set height of the bar
      .attr('fill', d => d.color) // Set fill color from data
      .on('mouseover', (event, d) => { // Show tooltip on mouse over
        tooltip.transition().duration(200).style('opacity', 0.9);
        tooltip.html(`${d.name}: ${formatNumberWithCommas(d.total)}`)
          .style('left', (event.pageX + 10) + 'px') // Position tooltip to the right of the cursor
          .style('top', (event.pageY - 20) + 'px'); // Position tooltip above the cursor
      })
      .on('mousemove', (event) => { // Move tooltip with the cursor
        tooltip.style('left', (event.pageX + 10) + 'px')
          .style('top', (event.pageY - 20) + 'px');
      })
      .on('mouseout', () => { // Hide tooltip on mouse out
        tooltip.transition().duration(500).style('opacity', 0);
      });

    // Calculate the total sum of all bars in the stacked bar
    const totalBarSum = d3.sum(data, d => d.total);
    const formattedTotal = formatNumber(totalBarSum); // Format the total sum

    /**
     * Append a text element to display the total sum just outside the end of the stacked bar.
     */
    svg.append('text')
      .attr('x', x(totalSum) + gap) // Position it just after the last bar
      .attr('y', height / 2 + 5) // Vertically center the text relative to the bar
      .attr('fill', 'black') // Set text color
      .style('font-size', '12px') // Set font size
      .style('text-anchor', 'start') // Align text to start (left)
      .text(`${formattedTotal}`); // Display the formatted total sum

    /**
     * Calculate and append the percentage of the total sum relative to the largest scenario.
     * This percentage is displayed at the far right end of the chart.
     */
    const percentageOfLargest = ((totalBarSum / largestScenarioTotal) * 100).toFixed(0); // Calculate percentage

    svg.append('text')
      .attr('x', percentageRightEnd - 5) // Position at the far right minus a small padding
      .attr('y', height / 2 + 5) // Vertically center the text relative to the bar
      .attr('fill', 'black') // Set text color
      .style('font-size', '12px') // Set font size
      .style('font-weight', 'bold') // Make the percentage text bold
      .style('text-anchor', 'end') // Align text to end (right)
      .text(`${percentageOfLargest}%`); // Display the calculated percentage

    // Cleanup function to remove the tooltip when the component unmounts
    return () => {
      tooltip.remove();
    };
  }, [properties, mode, maxTotal]); // Re-run the effect when properties, mode, or maxTotal change

  // If there are no valid properties, render nothing
  if (!properties || properties.length === 0 || properties.every(prop => !prop.total || prop.total <= 0)) {
    return null;
  }

  // Render the SVG element using the ref
  return <svg ref={ref}></svg>;
};

export default ScenarioStackedBarChart;
