import React, { useRef, useEffect } from 'react';
import * as d3 from 'd3';
import { renderEnergySummaryGraph } from './chartarea/SummaryGraph';
import { renderEnergyAnnualEndUseGraph } from './chartarea/EnergyAnnualEndUseGraph';
import { renderEnergyEndUseGraph } from './chartarea/EnergyEndUseGraph';
import { renderEnergyFuelUseGraph } from './chartarea/EnergyFuelUseGraph';
import { renderWinterEnergyLoadGraph } from './chartarea/WinterEnergyLoadGraph';
import { renderSummerEnergyLoadGraph } from './chartarea/SummerEnergyLoadGraph';
import { renderLoadDurationCurveGraph } from './chartarea/LoadDurationCurveGraph';
import { renderCO2SummaryGraph } from './chartarea/EnergyCO2SummaryGraph';
import { renderHistogramGraph } from './chartarea/HistogramGraph';
import { renderMobilityDayTripGraph } from './chartarea/MobilityDayTripGraph';
import { renderMobilityTimeTripGraph } from './chartarea/MobilityTimeTripGraph';

const ChartArea = ({ 
  config, 
  selectedDashboard, 
  chartData, 
  endUseData, 
  selectedFeatures, 
  chartType, 
  localSettings // Add localSettings as a prop
}) => {
  const chartRef = useRef();
  const dashboardConfig = config[selectedDashboard];

  useEffect(() => {
    d3.select(chartRef.current).selectAll('*').remove();

    switch (chartType) {
      case 'Summary Table':
        renderEnergySummaryGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Annual End Use':
        renderEnergyAnnualEndUseGraph(chartRef, selectedFeatures, endUseData, dashboardConfig);
        break;  

      case 'Monthly End Use':
        renderEnergyEndUseGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Monthly Fuel Use':
        renderEnergyFuelUseGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Winter Energy Load (Typical Week)':
        renderWinterEnergyLoadGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Summer Energy Load (Typical Week)':
        renderSummerEnergyLoadGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Load Duration Curve':
        renderLoadDurationCurveGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'CO2 Summary':
        renderCO2SummaryGraph(chartRef, selectedFeatures, dashboardConfig);
        break;

      case 'Histogram':
        renderHistogramGraph(chartData, chartRef, dashboardConfig, localSettings.min, localSettings.max, localSettings.bincount);
        break;

        case 'Week Trip':
        renderMobilityDayTripGraph(chartRef, selectedFeatures, dashboardConfig, false);
          break;

        case 'Weekend Trip':
          renderMobilityTimeTripGraph(chartRef, selectedFeatures, dashboardConfig, true);
            break;

        case 'Weekday Trip':
           renderMobilityTimeTripGraph(chartRef, selectedFeatures, dashboardConfig, false);
        break;

      default:
        renderHistogramGraph(chartData, chartRef, dashboardConfig, localSettings.min, localSettings.max, localSettings.bincount);
    }
  }, [chartData, chartType, selectedFeatures, dashboardConfig, localSettings]); // Add localSettings to dependencies

  return <div ref={chartRef} className="chart-area"></div>;
};

export default ChartArea;
