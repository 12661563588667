import * as d3 from 'd3';

export const renderSummerEnergyLoadGraph = (chartRef, selectedFeatures, dashboardConfig) => {
  if (!chartRef?.current) {
    console.error('Invalid chartRef: chartRef is null or undefined.');
    return;
  }

  if (!Array.isArray(selectedFeatures) || selectedFeatures.length === 0) {
    console.warn('No selected features to process.');
    return;
  }

  const filteredFeatures = selectedFeatures.filter(
    (feature) => feature?.geometry?.type !== 'Point'
  );

  if (filteredFeatures.length === 0) {
    console.warn('No valid features found after filtering.');
    return;
  }

  // Initialize the data arrays to hold 169 data points for the hour range 5285-5453
  const gasData = Array(169).fill(0);
  const electricityData = Array(169).fill(0);

  filteredFeatures.forEach((feature) => {
    const gas = feature?.properties?.["GasDemand_KWh"];
    const electricity = feature?.properties?.["ElectricityDemand_KWh"];

    if (!Array.isArray(gas) || !Array.isArray(electricity)) {
      console.warn(`Feature with id ${feature?.id || 'unknown'} has invalid gas or electricity data.`);
      return;
    }

    for (let hour = 5285; hour <= 5453; hour++) {
      const index = hour - 5285;  // Map hour to index 0-168
      gasData[index] += gas[hour] || 0;
      electricityData[index] += electricity[hour] || 0;
    }
  });

  const margin = { top: 20, right: 30, bottom: 60, left: 50 };
  const defaultWidth = 700;
  const defaultHeight = 250;
  const width = (dashboardConfig?.chartSettings?.width || defaultWidth) / 2 - margin.left - margin.right;
  const height = (dashboardConfig?.chartSettings?.height || defaultHeight) - margin.top - margin.bottom;

  if (width <= 0 || height <= 0) {
    console.error('Invalid dimensions for the chart. Check the dashboardConfig settings.');
    return;
  }

  const svg = d3.select(chartRef.current)
    .append('svg')
    .attr('width', width + margin.left + margin.right)
    .attr('height', height + margin.top + margin.bottom)
    .append('g')
    .attr('transform', `translate(${margin.left},${margin.top})`);

  const x = d3.scaleLinear()
    .domain([0, 169])  // Adjust domain to fit the range of data points (169 hours)
    .range([0, width]);

  const maxYValue = d3.max([...gasData, ...electricityData]);
  if (maxYValue === undefined || isNaN(maxYValue)) {
    console.error('Invalid Y-axis max value. Check the data arrays.');
    return;
  }

  const y = d3.scaleLinear()
    .domain([0, maxYValue])
    .nice()
    .range([height, 0]);

  const lineGenerator = d3.line()
    .x((d, i) => x(i))
    .y(d => y(d));

  svg.append('path')
    .datum(electricityData)
    .attr('fill', 'none')
    .attr('stroke', '#1569C7')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  svg.append('path')
    .datum(gasData)
    .attr('fill', 'none')
    .attr('stroke', '#FFB100')
    .attr('stroke-width', 2)
    .attr('d', lineGenerator);

  // Adjust x-axis ticks if needed
  const customXAxisTicks = [24, 48, 72, 96, 120, 144];
  svg.append('g')
    .attr('class', 'x-axis')
    .attr('transform', `translate(0,${height})`)
    .call(d3.axisBottom(x).tickValues(customXAxisTicks));

  svg.append('g')
    .attr('class', 'y-axis')
    .call(d3.axisLeft(y).ticks(5).tickFormat(d3.format('.2s')));

  svg.selectAll('.y-axis text')
    .style('font-size', '10px')
    .style('fill', '#000');

   // Y-axis label
   svg.append("text")
   .attr("class", "y-axis-label")
   .attr("x", -(height / 2))
   .attr("y", -margin.left + 15)
   .attr("text-anchor", "middle")
   .attr("transform", "rotate(-90)")
   .style("font-size", "10px")
   .text("kW");

 // X-axis label
 svg.append("text")
   .attr("class", "x-axis-label")
   .attr("x", width)
   .attr("y", height + 20)
   .attr("text-anchor", "middle")
   .style("font-size", "10px")
   .text("Hour");

  const legend = svg.append('g')
    .attr('transform', `translate(0, ${height + 30})`);

  legend.append('rect')
    .attr('x', 0)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#1569C7');

  legend.append('text')
    .attr('x', 20)
    .attr('y', 5)
    .text('Electricity')
    .style('font-size', '10px')
    .style('fill', '#000');

  legend.append('rect')
    .attr('x', 100)
    .attr('y', -3)
    .attr('width', 10)
    .attr('height', 10)
    .attr('fill', '#FFB100');

  legend.append('text')
    .attr('x', 120)
    .attr('y', 5)
    .text('Gas')
    .style('font-size', '10px')
    .style('fill', '#000');
};
