import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import LayersIcon from "@mui/icons-material/Layers";

function RoadSurfaceToggle({ mapRef }) {
  const [roadSurfaceOnTop, setRoadSurfaceOnTop] = useState(false);

  const toggleRoadSurfaceLayers = () => {
    const layers = mapRef.current.getStyle().layers;
    const roadSurfaceLayers = layers.filter((layer) => layer.id.includes("road"));

    roadSurfaceLayers.forEach((layer) => {
      if (mapRef.current.getLayer(layer.id)) {
        if (!roadSurfaceOnTop) {
          // Move road layers to the top without specifying a reference layer
          mapRef.current.moveLayer(layer.id);
        } else {
          // Move road layers to the bottom by specifying the first layer as reference
          mapRef.current.moveLayer(layer.id, layers[0].id);
        }
      }
    });

    // Toggle the state to reflect the new position of the road surface layers
    setRoadSurfaceOnTop(!roadSurfaceOnTop);
  };

  return (
    <IconButton onClick={toggleRoadSurfaceLayers} color="primary">
      <LayersIcon color={roadSurfaceOnTop ? "disabled" : "primary"} />
    </IconButton>
  );
}

export default RoadSurfaceToggle;
