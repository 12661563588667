import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  TextField,
  MenuItem,
  IconButton,
  ClickAwayListener,
  Paper,
  Typography,
  Menu,
  Button,
  MenuItem as DropdownMenuItem,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CloseIcon from "@mui/icons-material/Close";

import { toPng } from "html-to-image";

const ChartControls = ({
  config,
  selectedDashboard,
  selectedProperty,
  setSelectedProperty,
  chartType,
  setChartType,
  localSettings,
  setLocalSettings,
  chartRef, // Reference to the chart DOM element
}) => {
  const dashboardConfig = config[selectedDashboard];
  const [showSettings, setShowSettings] = useState(false);
  const [exportAnchorEl, setExportAnchorEl] = useState(null); // For the export dropdown menu
  const [customDimensions, setCustomDimensions] = useState({
    width: 800,
    height: 600,
  }); // Default dimensions
  const settingsRef = useRef(null);
  const mouseDownInsideSettings = useRef(false);

  const availableChartTypes = dashboardConfig?.chartSettings?.type?.filter(
    (type) => type !== "ScenarioStackedBarChart"
  ) || ["Histogram"];

  useEffect(() => {
    if (dashboardConfig) {
      if (!selectedProperty) {
        const firstProperty = dashboardConfig.chartProperties?.[0];
        setSelectedProperty(firstProperty);
      }
      if (!chartType || !availableChartTypes.includes(chartType)) {
        const firstChartType = availableChartTypes[0];
        setChartType(firstChartType);
      }
    }
  }, [
    dashboardConfig,
    selectedProperty,
    chartType,
    setSelectedProperty,
    setChartType,
    availableChartTypes,
  ]);

  const chartProperties = dashboardConfig?.chartProperties || [];

  const toggleSettings = () => {
    setShowSettings(!showSettings);
  };

  const handleMouseDown = (event) => {
    if (settingsRef.current && settingsRef.current.contains(event.target)) {
      mouseDownInsideSettings.current = true;
    } else {
      mouseDownInsideSettings.current = false;
    }
  };

  const handleClickAway = () => {
    if (!mouseDownInsideSettings.current) {
      setShowSettings(false);
    }
    mouseDownInsideSettings.current = false;
  };

  const handleChange = (field) => (event) => {
    const newValue = event.target.value;
    if (newValue === "" && field !== "bincount") {
      setLocalSettings((prevSettings) => ({
        ...prevSettings,
        [field]: newValue,
      }));
      return;
    }

    const validatedValue =
      field === "bincount"
        ? Math.max(1, parseInt(newValue, 10)) || 10
        : validateMinMax(newValue, localSettings[field]);

    const updatedSettings = {
      ...localSettings,
      [field]: validatedValue,
    };

    if (field === "min" && validatedValue >= updatedSettings.max) {
      updatedSettings.min = Math.min(validatedValue, updatedSettings.max - 1);
    }

    setLocalSettings(updatedSettings);
  };

  const validateMinMax = (value, fallback) => {
    const numValue = parseFloat(value);
    if (isNaN(numValue)) {
      console.warn(`Invalid number '${value}', using fallback ${fallback}`);
      return fallback;
    }
    return numValue;
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleMouseDown);
    return () => {
      document.removeEventListener("mousedown", handleMouseDown);
    };
  }, []);

  // Handle dimension changes for PNG export
  const handleDimensionChange = (field) => (event) => {
    const newValue = parseInt(event.target.value, 10);
    setCustomDimensions((prev) => ({
      ...prev,
      [field]: isNaN(newValue) ? prev[field] : newValue,
    }));
  };

  // Function to export chart as PNG with custom dimensions
  const exportAsPng = () => {
    if (chartRef && chartRef.current) {
      toPng(chartRef.current, {
        width: customDimensions.width,
        height: customDimensions.height,
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.href = dataUrl;
          link.download = "chart.png";
          link.click();
        })
        .catch((error) => {
          console.error("Error exporting chart as PNG:", error);
        });
    } else {
      console.error("chartRef is not properly initialized.");
    }
  };

  // Handle export dropdown
  const handleExportClick = (event) => {
    setExportAnchorEl(event.currentTarget);
  };

  const handleExportClose = () => {
    setExportAnchorEl(null);
  };

  const handleExport = () => {
    handleExportClose();
    exportAsPng(); // Only PNG export now
  };

  return (
    <Box mt={2} width="100%">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginTop={"15px"}
      >
        <TextField
          select
          label="Select Chart Type"
          value={chartType || availableChartTypes[0]}
          onChange={(e) => setChartType(e.target.value)}
          fullWidth
        >
          {availableChartTypes.map((type) => (
            <MenuItem key={type} value={type}>
              {type}
            </MenuItem>
          ))}
        </TextField>
        <IconButton onClick={handleExportClick} style={{ marginLeft: "10px" }}>
          <MoreVertIcon />
        </IconButton>
      </Box>

      {chartType === "Histogram" && (
        <>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            marginTop={"15px"}
          >
            <TextField
              select
              label="Select Property"
              value={selectedProperty || chartProperties[0]}
              onChange={(e) => setSelectedProperty(e.target.value)}
              fullWidth
            >
              {chartProperties.map((prop) => (
                <MenuItem key={prop} value={prop}>
                  {prop}
                </MenuItem>
              ))}
            </TextField>
            <IconButton onClick={toggleSettings} style={{ marginLeft: "10px" }}>
              <SettingsIcon />
            </IconButton>
          </Box>

          <Box mt={2} display="flex" justifyContent="space-between">

          </Box>

          {showSettings && (
            <ClickAwayListener onClickAway={handleClickAway}>
              <Paper
                ref={settingsRef}
                elevation={3}
                style={{
                  position: "absolute",
                  zIndex: 1100,
                  padding: "10px",
                  top: "20px",
                  right: "20px",
                }}
              >
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="h6">Settings</Typography>
                  <IconButton onClick={toggleSettings}>
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Box mt={2}>
                  <TextField
                    label="Width"
                    type="number"
                    value={customDimensions.width}
                    onChange={handleDimensionChange("width")}
                    fullWidth
                  />
                </Box>
                <Box mt={2}>
                  <TextField
                    label="Height"
                    type="number"
                    value={customDimensions.height}
                    onChange={handleDimensionChange("height")}
                    fullWidth
                  />
                </Box>
                <Box mt={2} display="flex" justifyContent="center">
                  <Button
                    onClick={() =>
                      setCustomDimensions({ width: 800, height: 600 })
                    }
                    color="primary"
                  >
                    Reset Dimensions
                  </Button>
                </Box>
              </Paper>
            </ClickAwayListener>
          )}
        </>
      )}

      {/* Export button */}
      <Box mt={2} display="flex" justifyContent="space-between">
        <Menu
          anchorEl={exportAnchorEl}
          keepMounted
          open={Boolean(exportAnchorEl)}
          onClose={handleExportClose}
        >
          <DropdownMenuItem onClick={handleExport}>
            Export as PNG
          </DropdownMenuItem>
        </Menu>
      </Box>
    </Box>
  );
};

export default ChartControls;
